import {
  ADD_TO_COLLECTION,
  CollectionOptions,
  DELETE_FROM_COLLECTION,
  GET_COLLECTION,
  getCollectionByName,
} from '@dabapps/redux-api-collections/dist/collections';
import {
  hasFailed,
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { Dict } from '@dabapps/simple-records';
import { AxiosPromise } from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { Column, Row } from 'roe';

import {
  clearOptions,
  setOrdering,
  setPage,
  setSearch,
} from '../../actions/options';
import { AdminEditCreate } from '../../chadmin';
import { IItemConfig } from '../../chadmin';
import { AdminList } from '../../chadmin';
import { CollectionType, default as Collections } from '../../collections';
import { getFormErrors } from '../../responses';

import {
  getTerminologyChoices,
  getUsersChoices,
} from '../../selectors/institutions';
import { IStore } from '../../store/configureStore';
import { IInstitution } from '../../store/data-types/institution';
import { USERS } from '../users/config';
import {
  DETAIL_FIELD_OPTIONS,
  DETAIL_FIELDS,
  INSTITUTIONS,
  LIST_COLUMNS,
  TERMINOLOGY_OPTIONS_ROUTE,
} from './config';

interface IProps {
  institutions: ReadonlyArray<IInstitution>;
  loading: boolean;
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
  itemIsPending: boolean;
  errors: Dict<ReadonlyArray<string>> | undefined;
  count: number;
  options: CollectionOptions;
  detailFieldOptions: IItemConfig;
  destroy: typeof destroy;
  clearOptions(key: string): void;
  addItem(type: CollectionType, data: any, tag: string): AxiosPromise;
  deleteItem(id: any): void;
  search(searchTerm: string): void;
  changePage(pageNumber: number): void;
  sort(sortKey: string): void;
  getInstitutions(options?: CollectionOptions): void;
  getAllCollection(
    type: CollectionType,
    options: CollectionOptions,
    tag: string
  ): void;
}

const FORM_NAME = 'institution-list';

export class InstitutionsList extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.createItem = this.createItem.bind(this);
  }

  public componentDidMount() {
    // get collection with no options set
    this.props.clearOptions(INSTITUTIONS);
    this.props.getInstitutions();
    this.props.getAllCollection(USERS, {}, INSTITUTIONS);
    this.props.getAllCollection(TERMINOLOGY_OPTIONS_ROUTE, {}, INSTITUTIONS);
  }

  public componentWillReceiveProps(nextProps: IProps) {
    const { options, getInstitutions } = this.props;

    // if we've updated options, refetch the collection
    if (options !== nextProps.options) {
      getInstitutions(nextProps.options);
    }
  }

  public render() {
    const {
      loading,
      count,
      institutions,
      deleteItem,
      search,
      changePage,
      detailFieldOptions,
      sort,
      itemHasFailed,
      itemHasSucceeded,
      itemIsPending,
      errors,
      options: { ordering, reverseOrdering, page },
    } = this.props;

    return (
      <Row>
        <Column>
          <a href="#create" className="float-right button margin-top-large">
            Create Institution
          </a>
          <h3>Institutions</h3>
          <AdminList
            items={institutions}
            columns={LIST_COLUMNS}
            deleteItem={deleteItem}
            searchItems={search}
            sortItems={sort}
            sortBy={ordering}
            sortByReversed={reverseOrdering}
            changePage={changePage}
            listName={INSTITUTIONS}
            page={page}
            itemCount={count}
          />
          <hr />
          <h3 id="create" className="anchor-offset">
            Create Institution
          </h3>
          <p className="info">Fields marked with a * are required</p>
          <AdminEditCreate
            createItem={this.createItem}
            fields={DETAIL_FIELDS}
            itemOptions={detailFieldOptions}
            formName={FORM_NAME}
            setPendingUploadInForm={console.log}
            itemErrors={errors}
            loading={loading}
            itemHasFailed={itemHasFailed}
            itemHasSucceeded={itemHasSucceeded}
            itemIsPending={itemIsPending}
          />
        </Column>
      </Row>
    );
  }

  private createItem(data: any) {
    this.props
      .addItem(INSTITUTIONS, data, INSTITUTIONS)
      .then(() => this.props.destroy(FORM_NAME));
  }
}

const defaultOptions: CollectionOptions = {};

function mapStateToProps(state: IStore) {
  const { collections, responses, options } = state;

  const { results: institutions, count } = getCollectionByName(
    collections,
    'admin/institutions',
    INSTITUTIONS
  );

  const loading =
    isPending(responses, GET_COLLECTION, INSTITUTIONS) ||
    isPending(responses, GET_COLLECTION, USERS) ||
    isPending(responses, DELETE_FROM_COLLECTION, INSTITUTIONS);

  const usersChoices = getUsersChoices(state);

  const terminologyChoices = getTerminologyChoices(state);

  const detailFieldOptions: IItemConfig = {
    ...DETAIL_FIELD_OPTIONS,
    users: {
      label: 'Users',
      type: 'many',
      choices: usersChoices,
      clearable: false,
      fullWidth: true,
    },
    terminology_type: {
      choices: terminologyChoices,
      label: 'Terminology *',
      type: 'text',
    },
  };

  return {
    loading,
    count,
    institutions,
    detailFieldOptions,
    errors: getFormErrors(responses, ADD_TO_COLLECTION, INSTITUTIONS),
    itemHasFailed: hasFailed(responses, ADD_TO_COLLECTION, INSTITUTIONS),
    itemHasSucceeded: hasSucceeded(responses, ADD_TO_COLLECTION, INSTITUTIONS),
    itemIsPending: isPending(responses, ADD_TO_COLLECTION, INSTITUTIONS),
    options: options.get(INSTITUTIONS, defaultOptions),
  };
}

export default connect(mapStateToProps, {
  addItem: Collections.actions.addItem,
  destroy,
  clearOptions,
  getAllCollection: Collections.actions.getAllCollection,
  changePage: pageNumber => setPage(INSTITUTIONS, pageNumber),
  deleteItem: id =>
    Collections.actions.deleteItem(INSTITUTIONS, id, INSTITUTIONS),
  getInstitutions: (options = {}) =>
    Collections.actions.getCollection(INSTITUTIONS, options, INSTITUTIONS),
  search: searchTerm => setSearch(INSTITUTIONS, searchTerm),
  sort: sortKey => setOrdering(INSTITUTIONS, sortKey),
})(InstitutionsList);
