import { IItemConfig } from '../../chadmin';
import { TColumns } from '../../chadmin';
import { CollectionType } from '../../collections';
import {
  // tslint:disable-next-line:no-unused-variable
  IUser,
} from '../../store/data-types/user';

export const USERS: CollectionType = 'admin/users';

const DETAIL_ROUTE = '${id}';

function makeChoices(choices: ReadonlyArray<string>) {
  return choices.map(choice => {
    return {
      value: choice,
      display_name: choice,
    };
  });
}

// list
export const LIST_COLUMNS: TColumns<IUser> = [
  {
    name: 'username',
    display_name: 'Username',
    type: 'text',
    sortable: true,
  },
  { name: 'email', display_name: 'Email', type: 'text', sortable: true },
  {
    name: 'given_name',
    display_name: 'Given Name',
    type: 'text',
    sortable: true,
  },
  {
    name: 'family_name',
    display_name: 'Family Name',
    type: 'text',
    sortable: true,
  },
  {
    name: 'actions',
    display_name: 'Actions',
    type: 'detail',
    link_text: 'Manage',
    route: DETAIL_ROUTE,
  },
  { name: 'id', display_name: 'Delete', type: 'remove', sortable: false },
];

// detail
export const DETAIL_FIELDS = [
  'username',
  'unique_learner_number',
  'title',
  'given_name',
  'middle_name',
  'family_name',
  'birth_date',
  'gender',
  'email',
  'phone_number',
  'street_address',
  'postal_code',
  'locality',
  'region',
  'country',
  'is_active',
  'one_time_password',
];

export const DETAIL_FIELD_OPTIONS: IItemConfig = {
  birth_date: {
    label: 'Birth Date',
    max_length: 255,
    type: 'date',
  },
  country: {
    label: 'Country',
    max_length: 255,
    type: 'text',
  },
  email: {
    label: 'Email',
    max_length: 255,
    type: 'text',
  },
  family_name: {
    label: 'Family Name',
    max_length: 255,
    type: 'text',
  },
  given_name: {
    label: 'Given Name',
    max_length: 255,
    type: 'text',
  },
  is_active: {
    label: 'Is Active',
    type: 'boolean',
  },
  locality: {
    label: 'Locality',
    max_length: 255,
    type: 'text',
  },
  middle_name: {
    label: 'Middle Name',
    max_length: 255,
    type: 'text',
  },
  phone_number: {
    label: 'Phone Number',
    max_length: 255,
    type: 'text',
  },
  postal_code: {
    label: 'Post Code',
    max_length: 255,
    type: 'text',
  },
  region: {
    label: 'Region',
    max_length: 255,
    type: 'text',
  },
  street_address: {
    label: 'Street Address',
    max_length: 255,
    type: 'text',
  },
  username: {
    label: 'Username',
    max_length: 255,
    type: 'text',
  },
  one_time_password: {
    label: 'Temporary Password',
    max_length: 255,
    type: 'text',
  },
  gender: {
    label: 'Gender',
    max_length: 255,
    type: 'text',
    choices: makeChoices(['', 'Female', 'Male', 'Other']),
  },
  title: {
    label: 'Title',
    max_length: 255,
    type: 'text',
    choices: makeChoices([
      '',
      'Mr',
      'Mrs',
      'Miss',
      'Ms',
      'Reverand',
      'Professor',
      'Doctor',
    ]),
  },
  unique_learner_number: {
    label: 'VTCT Learner ID',
    max_length: 255,
    type: 'text',
  },
};
