import { SimpleRecord } from '@dabapps/simple-records';

export type IUser = Readonly<{
  id: string;
  email: string;
  name: string;
  given_name: string;
  family_name: string;
  middle_name: string;
  birth_date: string;
  phone_number: string;
  street_address: string;
  locality: string;
  region: string;
  postal_code: string;
  country: string;
  is_active: boolean;
  username: string;
  one_time_password: string;
  gender: string;
  title: string;
}>;

export const UserRecord = SimpleRecord<IUser>({
  birth_date: '',
  country: '',
  email: '',
  family_name: '',
  given_name: '',
  id: '',
  is_active: false,
  locality: '',
  middle_name: '',
  name: '',
  phone_number: '',
  postal_code: '',
  region: '',
  street_address: '',
  username: '',
  one_time_password: '',
  gender: '',
  title: '',
});
