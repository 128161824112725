import * as React from 'react';

import * as Select from 'react-select';
import FieldGroup from '../FieldGroup';
import {
  // tslint:disable-next-line:no-unused-variable
  IWrappedFieldProps,
  TChoice,
  TWrappedFieldProps,
} from './types';

export const FieldSelect = (
  props: React.HTMLProps<JSX.Element> & TWrappedFieldProps
) => {
  const { readOnly, config, name, errors, value, onChange } = props;

  const { choices = [], placeholder, clearable = true } = config;

  if (readOnly) {
    // find matching choice
    const [display = { value: '', display_name: value }] =
      choices && choices.filter(choice => choice.value === value);

    return (
      <FieldGroup config={config} errors={errors}>
        <span className="input">
          {display && display.display_name}
        </span>
      </FieldGroup>
    );
  }

  const options = [...choices];

  return (
    <FieldGroup config={config} errors={errors}>
      <Select
        clearable={clearable}
        className="select"
        labelKey="display_name"
        options={options}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        simpleValue
      />
    </FieldGroup>
  );
};

export default FieldSelect;
