import * as React from 'react';

import { WrappedFieldProps } from 'redux-form';
import { FormErrors } from '../../types';
import { getFieldErrors } from '../../utils';
import FieldBoolean from './field-types/FieldBoolean';
import FieldChar from './field-types/FieldChar';
import FieldDate from './field-types/FieldDate';
import FieldFile from './field-types/FieldFile';
import FieldImage from './field-types/FieldImage';
import FieldMany from './field-types/FieldMany';
import FieldNumber from './field-types/FieldNumber';
import FieldSelect from './field-types/FieldSelect';
import FieldStatic from './field-types/FieldStatic';
import { IConfig, TWrappedFieldProps } from './field-types/types';

function renderField(fieldConfig: IConfig, fieldProps: TWrappedFieldProps) {
  switch (fieldConfig.type) {
    case 'date':
      return <FieldDate {...fieldProps} />;
    case 'datetime':
      return <FieldDate {...fieldProps} isDateTime />;
    case 'image upload':
      return <FieldImage {...fieldProps} />;
    case 'file upload':
      return <FieldFile {...fieldProps} />;
    case 'integer':
    case 'float':
      return <FieldNumber {...fieldProps} />;
    case 'boolean':
      return <FieldBoolean {...fieldProps} />;
    case 'static':
      return <FieldStatic {...fieldProps} />;
    case 'many':
      return <FieldMany {...fieldProps} />;
    case 'text':
    default:
      if (fieldConfig.choices) {
        return <FieldSelect {...fieldProps} />;
      } else {
        return <FieldChar {...fieldProps} />;
      }
  }
}

export interface IFieldProps extends WrappedFieldProps<void> {
  isEditing?: boolean;
  fieldConfig: IConfig;
  formName: string;
  readOnly?: boolean;
  errors: FormErrors;
  setUpload?(file: File): void;
}

export const Field = (props: IFieldProps) => {
  const {
    readOnly,
    fieldConfig,
    input,
    errors,
    isEditing,
    formName,
    setUpload,
  } = props;

  const fieldProps = {
    ...input,
    config: fieldConfig,
    errors: getFieldErrors(errors, input && input.name),
    readOnly: readOnly || fieldConfig.readOnly,
    isEditing,
    formName,
    setUpload,
  };

  const renderedField = renderField(fieldConfig, fieldProps);

  const { help_text } = fieldConfig;

  if (help_text) {
    return (
      <div>
        <p className="admin-helptext">
          {help_text}
        </p>
        {renderedField}
      </div>
    );
  }

  return renderedField;
};

export default Field;
