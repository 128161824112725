import { getCollectionResultsByName } from '@dabapps/redux-api-collections/dist/collections';
import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { IStore } from '../store/configureStore';
// tslint:disable-next-line:no-unused-variable
import { IExamSpec } from '../store/data-types/exam-spec';
// tslint:disable-next-line:no-unused-variable
import { IInstitution } from '../store/data-types/institution';
import { truncateText } from '../utils';

export const SUBSCRIPTIONS = 'SUBSCRIPTIONS';

const getInstitutions = ({ collections }: IStore) =>
  getCollectionResultsByName(
    collections,
    'admin/institutions',
    SUBSCRIPTIONS
  ) || [];
const getQualifications = ({ collections }: IStore) =>
  getCollectionResultsByName(collections, 'admin/courses', SUBSCRIPTIONS) || [];

function formatInstitutionDisplayName(institution: IInstitution) {
  return institution.name + ' (' + institution.centre_number + ')';
}

export function formatQualificationDisplayName(
  qualificationName: string,
  qualificationExternalId: string
) {
  return `(${truncateText(qualificationExternalId, 9)}) ${qualificationName}`;
}

export function getQualificationName(qualification: IExamSpec): string {
  return formatQualificationDisplayName(
    qualification.name,
    qualification.external_id
  );
}

export function getQualificationNameWithQualificationCode(
  qualification: IExamSpec
): string {
  return formatQualificationDisplayName(
    `${qualification.qualification_code} ${qualification.name}`,
    qualification.external_id
  );
}

export const getInstitutionsValueMap = createSelector(
  getInstitutions,
  (institutions: ReadonlyArray<IInstitution>) =>
    institutions.reduce(
      (acc, institution) =>
        acc.set(institution.id, formatInstitutionDisplayName(institution)),
      Map()
    )
);

export const getQualificationsValueMap = createSelector(
  getQualifications,
  (quals: ReadonlyArray<IExamSpec>) =>
    quals.reduce(
      (acc, qual) => acc.set(qual.id, getQualificationName(qual)),
      Map()
    )
);

export const getInstitutionsChoices = createSelector(
  getInstitutions,
  (institutions: ReadonlyArray<IInstitution>) =>
    institutions.map(institution => ({
      value: institution.id,
      display_name: formatInstitutionDisplayName(institution),
    }))
);

export const getQualificationsChoices = createSelector(
  getQualifications,
  (quals: ReadonlyArray<IExamSpec>) =>
    quals.map(qual => ({
      value: qual.external_id,
      display_name: getQualificationNameWithQualificationCode(qual),
    }))
);
