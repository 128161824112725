import * as React from 'react';
import { Column, Row } from 'roe';
import { APP_NAME } from '../constants';

const Home: React.SFC<React.HTMLProps<HTMLDivElement>> = () =>
  <Row>
    <Column xs={12}>
      <h1>
        {APP_NAME} Admin
      </h1>
      <p>
        Description of the {APP_NAME} admin.
      </p>
    </Column>
  </Row>;

export default Home;
