import { RecordWithConstructor } from '@dabapps/simple-records';
import * as moment from 'moment';

interface IQualificationSubscriptionShared {
  id: string;
  name: string;
  institution: string;
  qualification: string;
  qualification_external_id: string;
  qualification_name: string;
  qualification_code: string;
}
// tslint:disable-next-line:no-unused-variable
export type IQualificationSubscriptionInput = Readonly<
  IQualificationSubscriptionShared & {
    active_from: string | Date;
    active_to: string | Date;
  }
>;
export type IQualificationSubscription = Readonly<
  IQualificationSubscriptionShared & {
    active_from: moment.Moment;
    active_to: moment.Moment;
  }
>;

export const QualificationSubscriptionRecord = RecordWithConstructor<
  IQualificationSubscriptionInput,
  IQualificationSubscription
>(
  {
    active_from: new Date(),
    active_to: new Date(),
    id: '',
    institution: '',
    name: '',
    qualification: '',
    qualification_external_id: '',
    qualification_name: '',
    qualification_code: '',
  },
  input => {
    return {
      ...input,
      active_from: moment.utc(input.active_from),
      active_to: moment.utc(input.active_to),
    };
  }
);
