import * as React from 'react';
import { TableCell, TableRow } from 'roe';

import AdminItemField from '../detail/AdminItemField';
import { IColumn, TColumns } from '../types';

export interface IProps<T> {
  item: T;
  fields?: TColumns<T>;
  removeItem: (itemId: string | number) => void;
}

export default class AdminListItem<T> extends React.PureComponent<
  IProps<T>,
  void
> {
  public render() {
    const { item, fields, removeItem } = this.props;

    return (
      <TableRow>
        {fields &&
          fields.map((field, idx) =>
            <TableCell key={idx}>
              <AdminItemField
                key={idx}
                item={item}
                field={field}
                removeItem={removeItem}
              />
            </TableCell>
          )}
      </TableRow>
    );
  }
}
