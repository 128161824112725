import { RecordWithConstructor } from '@dabapps/simple-records';

export type IExamSpec = Readonly<{
  id: string;
  name: string;
  external_id: string;
  qualification_code: string;
}>;

export const ExamSpecRecord = RecordWithConstructor<IExamSpec, IExamSpec>(
  {
    id: '',
    name: '',
    external_id: '',
    qualification_code: '',
  },
  input => {
    return {
      ...input,
      id: input.external_id,
    };
  }
);
