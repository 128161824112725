import { responsesReducer } from '@dabapps/redux-api-collections/dist/requests';
import { Map } from 'immutable';
import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import * as actions from '../actions';
import Collections from '../collections';
import form from './form';
import modals from './modals';
import optionsReducer from './options';

export function uiState(
  state: Map<string, boolean | string> = Map<string, boolean | string>(),
  action: actions.IAction<any, any>
) {
  switch (action.type) {
    case actions.SET_UI_STATE:
      return state.set(action.payload.key, action.payload.value);
    case actions.CLEAR_UI_STATE:
      return state.delete(action.payload.key);
    default:
      return state;
  }
}

export default combineReducers({
  collections: Collections.reducers.collectionsReducer,
  items: Collections.reducers.itemsReducer,
  options: optionsReducer,
  responses: responsesReducer,
  routing: routerReducer,
  form,
  modals,
  uiState,
});
