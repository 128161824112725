import * as React from 'react';

import { DataShape, FormProps } from '@types/redux-form';
import { Field, reduxForm } from 'redux-form';
import { Button, Column, Row } from 'roe';
import * as _ from 'underscore';
import { FormErrors } from '../../types';
import { getFieldErrors } from '../../utils';
import ChadminField from './Field';
import { IConfig, IItemConfig, TConfig } from './field-types/types';
import Messages from './Messages';

export const normalizeBoolean = (value: any) => Boolean(value);

export interface ISubmitButtonProps {
  readOnly?: boolean;
  isPending?: boolean;
  saveButtonText?: string;
}

export const SubmitButton = ({
  readOnly,
  isPending,
  saveButtonText,
}: ISubmitButtonProps) =>
  !readOnly && !isPending
    ? <Column>
        <Button
          type="submit"
          className="primary float-right margin-vertical-base"
        >
          {saveButtonText || 'Save'}
        </Button>
      </Column>
    : <noscript />;

export interface IFormProps<T> extends FormProps<DataShape, void, void> {
  title?: string;
  fieldConfig: IItemConfig;
  fieldClassName?: string;
  hideMessages?: boolean;
  isPending?: boolean;
  className?: string;
  readOnly?: boolean;
  hasSucceeded?: boolean;
  hasFailed?: boolean;
  errors?: FormErrors;
  setPendingUploadInForm?: (file?: File | string) => void;
  formName: string;
  isEditing?: boolean;
  saveButtonText?: string;
  fields: ReadonlyArray<string>;
  additionalSuccessMessage?: React.ReactNode;
  additionalFailureMessage?: React.ReactNode;
  onSubmit(data: T): void;
}

export class Form<T> extends React.PureComponent<IFormProps<T>, void> {
  public render() {
    const {
      handleSubmit,
      fieldConfig,
      readOnly,
      hideMessages,
      saveButtonText,
      className,
      fieldClassName,
      title,
      setPendingUploadInForm,
      formName,
      isEditing,
      fields,
      isPending,
      hasSucceeded,
      hasFailed,
      errors,
      additionalSuccessMessage,
      additionalFailureMessage,
    } = this.props;
    return (
      <Row>
        <form className={className} onSubmit={handleSubmit}>
          {fields.map(
            fieldName =>
              fieldConfig[fieldName]
                ? <Field
                    key={fieldName}
                    component={ChadminField}
                    name={fieldName}
                    fieldConfig={fieldConfig[fieldName]}
                    readOnly={readOnly}
                    className={fieldClassName}
                    setUpload={setPendingUploadInForm}
                    handleSubmit={handleSubmit}
                    formName={formName}
                    isEditing={isEditing}
                    errors={errors}
                    normalize={
                      fieldConfig[fieldName].type === 'boolean'
                        ? normalizeBoolean
                        : undefined
                    }
                  />
                : <noscript />
          )}
          {!hideMessages &&
            <Messages
              isPending={isPending}
              hasSucceeded={hasSucceeded}
              hasFailed={hasFailed}
              isEditing={isEditing}
              errors={getFieldErrors(errors, 'non_field_errors')}
              additionalSuccessMessage={additionalSuccessMessage}
              additionalFailureMessage={additionalFailureMessage}
            />}
          <SubmitButton
            readOnly={readOnly}
            isPending={isPending}
            saveButtonText={saveButtonText}
          />
        </form>
      </Row>
    );
  }
}

export default reduxForm({})(Form);
