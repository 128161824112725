import * as React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'roe';

import { IColumn, TColumns, TRows } from '../types';

export interface IProps<T> {
  className?: string;
  columns?: TColumns<T>;
  emptyMessage?: string;
  noHeader?: boolean;
  renderHeaders?: (columns: TColumns<T>) => React.ReactNode;
  renderRow: (row: T, index: number, columns?: TColumns<T>) => React.ReactNode;
  rows: TRows<T>;
}

export default class CustomTable<T> extends React.Component<IProps<T>, void> {
  public render() {
    const {
      className,
      columns,
      rows,
      emptyMessage,
      renderHeaders,
      renderRow,
    } = this.props;

    return (
      <Table fill className={className}>
        {columns &&
          renderHeaders &&
          <TableHead>
            <TableRow>
              {renderHeaders(columns)}
            </TableRow>
          </TableHead>}
        <TableBody>
          {rows.map((row: T, idx: number) => renderRow(row, idx, columns))}
          {rows.length === 0 &&
            emptyMessage &&
            <TableRow>
              <TableCell colSpan={columns ? columns.length : 1}>
                {emptyMessage}
              </TableCell>
            </TableRow>}
        </TableBody>
      </Table>
    );
  }
}
