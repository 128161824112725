import * as React from 'react';

import {
  ADD_TO_COLLECTION,
  CollectionOptions,
  DELETE_FROM_COLLECTION,
  GET_COLLECTION,
  getCollectionByName,
} from '@dabapps/redux-api-collections/dist/collections';
import {
  hasFailed,
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { Dict } from '@dabapps/simple-records';
import { connect } from 'react-redux';
import { Column, Row } from 'roe';
import {
  clearOptions,
  setOrdering,
  setPage,
  setSearch,
} from '../../actions/options';
import { AdminEditCreate } from '../../chadmin';
import { IItemConfig } from '../../chadmin';
import { AdminList } from '../../chadmin';
import { TColumns } from '../../chadmin';
import { CollectionType, default as Collections } from '../../collections';
import { getFormErrors } from '../../responses';
import {
  getInstitutionsChoices,
  getInstitutionsValueMap,
  SUBSCRIPTIONS,
} from '../../selectors/subscriptions';
import { IStore } from '../../store/configureStore';
import { IProductSubscription } from '../../store/data-types/product-subscription';
import { INSTITUTIONS } from '../institutions/config';
import Loading from '../loading';
import {
  DETAIL_FIELD_OPTIONS,
  DETAIL_FIELDS,
  LIST_COLUMNS,
  PRODUCT_SUBSCRIPTIONS,
} from './config';

interface IPropsWithoutActions {
  productSubscriptions: ReadonlyArray<IProductSubscription>;
  loading: boolean;
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
  itemIsPending: boolean;
  errors: Dict<ReadonlyArray<string>> | undefined;
  count: number;
  listColumns: TColumns<IProductSubscription>;
  detailFieldOptions: IItemConfig;
  options: CollectionOptions;
}
interface IProps extends IPropsWithoutActions {
  createItem(data: any): void;
  clearOptions(key: string): void;
  deleteItem(id: any): void;
  getAllCollection(
    type: CollectionType,
    options: CollectionOptions,
    tag: string
  ): void;
  sort(sortKey: string): void;
  changePage(pageNumber: number): void;
  search(searchTerm: string): void;
  getSubscriptions(options?: CollectionOptions): void;
}

const FORM_NAME = 'product-subscription-list';

export class ProductSubscriptionsList extends React.PureComponent<
  IProps,
  void
> {
  public componentDidMount() {
    // get collection with no options set
    this.props.clearOptions(PRODUCT_SUBSCRIPTIONS);
    this.props.getSubscriptions();
    this.props.getAllCollection(INSTITUTIONS, {}, SUBSCRIPTIONS);
  }

  public componentWillReceiveProps(nextProps: IProps) {
    const { options, getSubscriptions } = this.props;

    // if we've updated options, refetch the collection
    if (options !== nextProps.options) {
      getSubscriptions(nextProps.options);
    }
  }

  public render() {
    const {
      loading,
      productSubscriptions,
      count,
      createItem,
      deleteItem,
      listColumns,
      detailFieldOptions,
      search,
      sort,
      changePage,
      itemHasFailed,
      itemHasSucceeded,
      itemIsPending,
      errors,
      options: { ordering, reverseOrdering, page },
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    return (
      <Row>
        <Column>
          <a href="#create" className="float-right button margin-top-large">
            Create product subscription
          </a>
          <h3>Product subscriptions</h3>
          <AdminList
            items={productSubscriptions}
            columns={listColumns}
            deleteItem={deleteItem}
            searchItems={search}
            sortItems={sort}
            sortBy={ordering}
            sortByReversed={reverseOrdering}
            changePage={changePage}
            listName={PRODUCT_SUBSCRIPTIONS}
            page={page}
            itemCount={count}
          />
          <hr />
          <h3 id="create" className="anchor-offset">
            Create Product Subscription
          </h3>
          <AdminEditCreate
            createItem={createItem}
            fields={DETAIL_FIELDS}
            itemOptions={detailFieldOptions}
            formName={FORM_NAME}
            setPendingUploadInForm={console.log}
            itemErrors={errors}
            loading={loading}
            itemHasFailed={itemHasFailed}
            itemHasSucceeded={itemHasSucceeded}
            itemIsPending={itemIsPending}
          />
        </Column>
      </Row>
    );
  }
}

const defaultOptions: CollectionOptions = {};

function mapStateToProps(state: IStore): IPropsWithoutActions {
  const { collections, responses, options } = state;

  const { results: productSubscriptions, count } = getCollectionByName(
    collections,
    'admin/productsubscriptions',
    PRODUCT_SUBSCRIPTIONS
  );

  const loading =
    isPending(responses, GET_COLLECTION, PRODUCT_SUBSCRIPTIONS) ||
    isPending(responses, GET_COLLECTION, INSTITUTIONS) ||
    isPending(responses, DELETE_FROM_COLLECTION, PRODUCT_SUBSCRIPTIONS);

  const valueMap = getInstitutionsValueMap(state);

  const listColumns = [...LIST_COLUMNS];
  listColumns.splice(2, 0, {
    display_name: 'Institution',
    name: 'institution',
    type: 'custom',
    customItemHandler: (item: IProductSubscription) =>
      valueMap.get(item.institution, null),
  });

  const choices = getInstitutionsChoices(state);

  const detailFieldOptions: IItemConfig = {
    ...DETAIL_FIELD_OPTIONS,
    institution: {
      label: 'Institution',
      type: 'text',
      choices,
    },
  };

  return {
    loading,
    errors: getFormErrors(responses, ADD_TO_COLLECTION, PRODUCT_SUBSCRIPTIONS),
    itemHasFailed: hasFailed(
      responses,
      ADD_TO_COLLECTION,
      PRODUCT_SUBSCRIPTIONS
    ),
    itemHasSucceeded: hasSucceeded(
      responses,
      ADD_TO_COLLECTION,
      PRODUCT_SUBSCRIPTIONS
    ),
    itemIsPending: isPending(
      responses,
      ADD_TO_COLLECTION,
      PRODUCT_SUBSCRIPTIONS
    ),
    count,
    productSubscriptions: productSubscriptions as any, // FIXME
    listColumns,
    detailFieldOptions,
    options: options.get(PRODUCT_SUBSCRIPTIONS, defaultOptions),
  };
}

export default connect(mapStateToProps, {
  getAllCollection: Collections.actions.getAllCollection,
  clearOptions,
  changePage: pageNumber => setPage(PRODUCT_SUBSCRIPTIONS, pageNumber),
  createItem: data =>
    Collections.actions.addItem(
      PRODUCT_SUBSCRIPTIONS,
      data,
      PRODUCT_SUBSCRIPTIONS
    ),
  deleteItem: id =>
    Collections.actions.deleteItem(
      PRODUCT_SUBSCRIPTIONS,
      id,
      PRODUCT_SUBSCRIPTIONS
    ),
  getSubscriptions: (options = {}) =>
    Collections.actions.getCollection(
      PRODUCT_SUBSCRIPTIONS,
      options,
      PRODUCT_SUBSCRIPTIONS
    ),
  search: searchTerm => setSearch(PRODUCT_SUBSCRIPTIONS, searchTerm),
  sort: sortKey => setOrdering(PRODUCT_SUBSCRIPTIONS, sortKey),
})(ProductSubscriptionsList);
