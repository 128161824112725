import * as React from 'react';
import {
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'roe';

export interface IColumnData<TData> {
  headerLabel: string;
  headerRenderer?: React.ReactNode;
  key: string;
  sortable: boolean;
  width?: number;
  className?: string;
  content(data: TData): React.ReactNode;
}

export interface IBaseTableProps<TData> {
  loading?: boolean;
  headers: Array<IColumnData<TData>>;
  fixRowHeaders?: boolean;
  loadingRenderer?: () => React.ReactNode;
}

export interface IProps<TData> extends IBaseTableProps<TData> {
  items: ReadonlyArray<TData>;
}

export default class CollectionTable<
  TData extends { id: string }
> extends React.PureComponent<IProps<TData>, void> {
  public render() {
    const { loadingRenderer } = this.props;
    const firstColumnWidth = this.props.headers.length
      ? this.props.headers[0].width
      : 100;
    const tableProps = this.props.fixRowHeaders
      ? {
          fixRowHeaders: this.props.fixRowHeaders,
          rowHeaderWidth: firstColumnWidth,
        }
      : {};

    return (
      <Row>
        <Table className="class-table" fill {...tableProps}>
          {this.renderHeaders()}
          <TableBody>
            {this.props.loading
              ? <tr>
                  <td colSpan={this.props.headers.length}>
                    {loadingRenderer ? loadingRenderer() : 'Loading...'}
                  </td>
                </tr>
              : this.props.items.map(item => {
                  return this.renderRow(item);
                })}
          </TableBody>
        </Table>
      </Row>
    );
  }

  private renderHeaders() {
    return (
      <TableHead>
        <TableRow>
          {this.props.headers.map((header, index) =>
            <TableHeader
              key={header.headerLabel}
              width={header.width}
              className={header.className}
            >
              {header.headerRenderer
                ? header.headerRenderer
                : header.headerLabel}
            </TableHeader>
          )}
        </TableRow>
      </TableHead>
    );
  }

  private renderRow(collectionItem: TData) {
    const [first, ...rest] = this.props.headers;
    if (!first) {
      return;
    }
    if (this.props.fixRowHeaders) {
      return (
        <TableRow key={collectionItem.id}>
          <TableHeader width={first.width}>
            {first.content(collectionItem)}
          </TableHeader>
          {rest.map(header =>
            <TableCell key={header.headerLabel} width={header.width}>
              {header.content(collectionItem)}
            </TableCell>
          )}
        </TableRow>
      );
    } else {
      return (
        <TableRow key={collectionItem.id}>
          {this.props.headers.map(header =>
            <TableCell key={header.headerLabel} width={header.width}>
              {header.content(collectionItem)}
            </TableCell>
          )}
        </TableRow>
      );
    }
  }
}
