import * as React from 'react';

// tslint:disable-next-line:no-unused-variable
import { IColumn } from '../types';

import {
  FORMAT_DATE_TIME_ADMIN,
  formatDateTime,
  getIn,
  parseObjectPropertyString,
} from '../utils';

export interface IProps<T> {
  field: IColumn<T>;
  item: T;
  removeItem: (value: any, name: string) => void;
}

export default class AdminItemField<T> extends React.PureComponent<
  IProps<T>,
  void
> {
  public render() {
    const { item, field, removeItem } = this.props;
    const { type, name, route, customItemHandler, link_text } = field;
    const value = name && getIn(item, name);

    switch (type) {
      case 'boolean':
        return value ? <span>Y</span> : <span>N</span>;
      case 'detail':
        return (
          <span>
            {route &&
              <a href={parseObjectPropertyString(item, route)}>
                {link_text || (name && value)}
              </a>}
          </span>
        );
      case 'datetime':
        return (
          <span>
            {formatDateTime(value, ' ', FORMAT_DATE_TIME_ADMIN) || '--'}
          </span>
        );
      case 'remove':
        return (
          <span>
            <a onClick={() => removeItem(value, name)}>Remove</a>
          </span>
        );
      case 'custom':
        return (
          <span>
            {customItemHandler && customItemHandler(item)}
          </span>
        );
      case 'integer':
      case 'float':
        return (
          <span>
            {value}
          </span>
        );
      case 'position':
        return (
          <span>
            {value === -1 ? '--' : value}
          </span>
        );
      case 'text':
      default:
        return (
          <span>
            {value || '--'}
          </span>
        );
    }
  }
}
