import { List } from 'immutable';
import * as actions from '../actions/index';
import * as modalActions from '../actions/modals';

export default function(
  state: List<React.ReactNode> = List(),
  action: actions.IAction<React.ReactNode, any>
) {
  switch (action.type) {
    case modalActions.OPEN_MODAL:
      return state.push(action.payload);
    case modalActions.CLOSE_MODAL:
      return state.pop();
    default:
      return state;
  }
}
