import * as React from 'react';
import { Link } from 'react-router';
import { APP_NAME } from '../../constants';

interface IProps {
  pathname: string;
}

const NavBar: React.SFC<IProps & React.HTMLProps<HTMLDivElement>> = () =>
  <div className="navbar">
    <div className="container">
      <Link to="/">
        <h1 className="brand">
          {APP_NAME}
        </h1>
      </Link>
      <ul className="nav float-right">
        <li>
          <a href="/accounts/logout/">Logout</a>
        </li>
      </ul>
    </div>
  </div>;

export default NavBar;
