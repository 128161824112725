import * as React from 'react';

import {
  ADD_TO_COLLECTION,
  CollectionOptions,
  DELETE_FROM_COLLECTION,
  GET_COLLECTION,
  getCollectionByName,
} from '@dabapps/redux-api-collections/dist/collections';
import {
  hasFailed,
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { Dict } from '@dabapps/simple-records';
import { connect } from 'react-redux';
import { Column, Row } from 'roe';
import {
  clearOptions,
  setOrdering,
  setPage,
  setSearch,
} from '../../actions/options';
import { AdminEditCreate } from '../../chadmin';
import { IItemConfig } from '../../chadmin';
import { AdminList } from '../../chadmin';
import { TColumns } from '../../chadmin';
import { CollectionType, default as Collections } from '../../collections';
import { getFormErrors } from '../../responses';
import {
  getInstitutionsChoices,
  getInstitutionsValueMap,
  getQualificationsChoices,
  getQualificationsValueMap,
  SUBSCRIPTIONS,
} from '../../selectors/subscriptions';
import { IStore } from '../../store/configureStore';
import { IQualificationSubscription } from '../../store/data-types/qualification-subscription';
import { INSTITUTIONS } from '../institutions/config';
import Loading from '../loading';
import {
  DETAIL_FIELD_OPTIONS,
  DETAIL_FIELDS,
  LIST_COLUMNS,
  QUALIFICATION_SUBSCRIPTIONS,
} from './config';

interface IPropsWithActions {
  qualificationSubscriptions: ReadonlyArray<IQualificationSubscription>;
  loading: boolean;
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
  itemIsPending: boolean;
  errors: Dict<ReadonlyArray<string>> | undefined;
  count: number;
  listColumns: TColumns<IQualificationSubscription>;
  detailFieldOptions: IItemConfig;
  options: CollectionOptions;
}
interface IProps extends IPropsWithActions {
  changePage(pageNumber: number): void;
  clearOptions(key: string): void;
  createItem(data: any): void;
  deleteItem(id: any): void;
  getAllCollection(
    type: CollectionType,
    options: CollectionOptions,
    tag: string
  ): void;
  sort(sortKey: string): void;
  search(searchTerm: string): void;
  getSubscriptions(options?: CollectionOptions): void;
}

const FORM_NAME = 'qualification-subscription-list';

export class QualificationSubscriptionsList extends React.PureComponent<
  IProps,
  void
> {
  public componentDidMount() {
    // get collection with no options set
    this.props.clearOptions(QUALIFICATION_SUBSCRIPTIONS);
    this.props.getSubscriptions();
    this.props.getAllCollection(INSTITUTIONS, {}, SUBSCRIPTIONS);
    this.props.getAllCollection('admin/courses', {}, SUBSCRIPTIONS);
  }

  public componentWillReceiveProps(nextProps: IProps) {
    const { options, getSubscriptions } = this.props;

    // if we've updated options, refetch the collection
    if (options !== nextProps.options) {
      getSubscriptions(nextProps.options);
    }
  }

  public render() {
    const {
      loading,
      qualificationSubscriptions,
      count,
      createItem,
      deleteItem,
      listColumns,
      detailFieldOptions,
      search,
      changePage,
      sort,
      itemHasFailed,
      itemHasSucceeded,
      itemIsPending,
      errors,
      options: { ordering, reverseOrdering, page },
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    return (
      <Row>
        <Column>
          <a href="#create" className="float-right button margin-top-large">
            Create qualification subscription
          </a>
          <h3>Qualification subscriptions</h3>
          <AdminList
            items={qualificationSubscriptions}
            columns={listColumns}
            deleteItem={deleteItem}
            searchItems={search}
            sortItems={sort}
            sortBy={ordering}
            sortByReversed={reverseOrdering}
            changePage={changePage}
            listName={QUALIFICATION_SUBSCRIPTIONS}
            page={page || 1}
            itemCount={count}
          />
          <hr />
          <h3 id="create" className="anchor-offset">
            Create Qualification Subscription
          </h3>
          <AdminEditCreate
            createItem={createItem}
            fields={DETAIL_FIELDS}
            itemOptions={detailFieldOptions}
            formName={FORM_NAME}
            setPendingUploadInForm={console.log}
            itemErrors={errors}
            loading={loading}
            itemHasFailed={itemHasFailed}
            itemHasSucceeded={itemHasSucceeded}
            itemIsPending={itemIsPending}
          />
        </Column>
      </Row>
    );
  }
}

const defaultOptions: CollectionOptions = {};

function mapStateToProps(state: IStore): IPropsWithActions {
  const { collections, responses, options } = state;

  const { results: qualificationSubscriptions, count } = getCollectionByName(
    collections,
    'admin/qualificationsubscriptions',
    QUALIFICATION_SUBSCRIPTIONS
  );

  const loading =
    isPending(responses, GET_COLLECTION, INSTITUTIONS) ||
    isPending(responses, GET_COLLECTION, QUALIFICATION_SUBSCRIPTIONS) ||
    isPending(responses, GET_COLLECTION, 'admin/courses') ||
    isPending(responses, DELETE_FROM_COLLECTION, QUALIFICATION_SUBSCRIPTIONS);

  const institutionsValueMap = getInstitutionsValueMap(state);
  const qualificationsValueMap = getQualificationsValueMap(state);

  const listColumns = [...LIST_COLUMNS];
  listColumns.splice(
    2,
    0,
    {
      display_name: 'Qualification',
      name: 'qualification',
      type: 'custom',
      customItemHandler: (item: IQualificationSubscription) =>
        qualificationsValueMap.get(item.qualification, '--'),
    },
    {
      display_name: 'Qualification Code',
      name: 'qualification_code',
      type: 'text',
    },
    {
      display_name: 'Institution',
      name: 'institution',
      type: 'custom',
      customItemHandler: (item: IQualificationSubscription) =>
        institutionsValueMap.get(item.institution, '--'),
    }
  );

  const institutionChoices = getInstitutionsChoices(state);
  const qualificationChoices = getQualificationsChoices(state);

  const detailFieldOptions: IItemConfig = {
    ...DETAIL_FIELD_OPTIONS,
    institution: {
      label: 'Institution',
      type: 'text',
      choices: institutionChoices,
    },
    qualification: {
      label: 'Qualification',
      type: 'text',
      choices: qualificationChoices,
    },
  };

  return {
    loading,
    count,
    qualificationSubscriptions: qualificationSubscriptions as any, // FIXME
    listColumns,
    detailFieldOptions,
    errors: getFormErrors(
      responses,
      ADD_TO_COLLECTION,
      QUALIFICATION_SUBSCRIPTIONS
    ),
    itemHasFailed: hasFailed(
      responses,
      ADD_TO_COLLECTION,
      QUALIFICATION_SUBSCRIPTIONS
    ),
    itemHasSucceeded: hasSucceeded(
      responses,
      ADD_TO_COLLECTION,
      QUALIFICATION_SUBSCRIPTIONS
    ),
    itemIsPending: isPending(
      responses,
      ADD_TO_COLLECTION,
      QUALIFICATION_SUBSCRIPTIONS
    ),
    options: options.get(QUALIFICATION_SUBSCRIPTIONS, defaultOptions),
  };
}

export default connect(mapStateToProps, {
  getAllCollection: Collections.actions.getAllCollection,
  clearOptions,
  changePage: pageNumber => setPage(QUALIFICATION_SUBSCRIPTIONS, pageNumber),
  createItem: data =>
    Collections.actions.addItem(
      QUALIFICATION_SUBSCRIPTIONS,
      data,
      QUALIFICATION_SUBSCRIPTIONS
    ),
  deleteItem: id =>
    Collections.actions.deleteItem(
      QUALIFICATION_SUBSCRIPTIONS,
      id,
      QUALIFICATION_SUBSCRIPTIONS
    ),
  getSubscriptions: (options = {}) =>
    Collections.actions.getCollection(
      QUALIFICATION_SUBSCRIPTIONS,
      options,
      QUALIFICATION_SUBSCRIPTIONS
    ),
  search: searchTerm => setSearch(QUALIFICATION_SUBSCRIPTIONS, searchTerm),
  sort: sortKey => setOrdering(QUALIFICATION_SUBSCRIPTIONS, sortKey),
})(QualificationSubscriptionsList);
