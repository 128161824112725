import { IItemConfig } from '../../chadmin';
import { TColumns } from '../../chadmin';
import { CollectionType } from '../../collections';
import {
  // tslint:disable-next-line:no-unused-variable
  IQualificationSubscription,
} from '../../store/data-types/qualification-subscription';

export const QUALIFICATION_SUBSCRIPTIONS: CollectionType =
  'admin/qualificationsubscriptions';

const DETAIL_ROUTE = '${id}';

// list
export const LIST_COLUMNS: TColumns<IQualificationSubscription> = [
  { name: 'name', display_name: 'Name', type: 'text', sortable: true },
  {
    name: 'active_from',
    display_name: 'Active From',
    type: 'datetime',
    sortable: true,
  },
  {
    name: 'active_to',
    display_name: 'Active To',
    type: 'datetime',
    sortable: true,
  },
  {
    name: 'actions',
    display_name: 'Actions',
    type: 'detail',
    link_text: 'Manage',
    route: DETAIL_ROUTE,
  },
  { name: 'id', display_name: 'Delete', type: 'remove', sortable: false },
];

// detail
export const DETAIL_FIELDS = [
  'name',
  'institution',
  'qualification',
  'active_from',
  'active_to',
];

export const DETAIL_FIELD_OPTIONS: IItemConfig = {
  active_from: {
    label: 'Active From',
    type: 'datetime',
  },
  active_to: {
    label: 'Active To',
    type: 'datetime',
  },
  name: {
    label: 'Name',
    type: 'text',
  },
  qualification_id: {
    label: 'Qualification ID',
    type: 'text',
  },
};
