import * as React from 'react';
import { DataShape, Field, FormProps, reduxForm } from 'redux-form';
import { Button, FormGroup } from 'roe';

export interface IProps
  extends FormProps<DataShape, void, void>,
    React.HTMLProps<JSX.Element> {
  loading?: boolean;
}

export class AdminListSearch extends React.PureComponent<IProps, undefined> {
  public render() {
    const { loading, handleSubmit } = this.props;
    return (
      <div>
        {
          <FormGroup block className="float-right">
            <form onSubmit={handleSubmit}>
              <span className="admin-list-search-container pull-right">
                <Field
                  name="search"
                  component="input"
                  type="search"
                  placeholder="Search for..."
                  disabled={loading}
                />
                <Button className="primary" type="submit">
                  Search
                </Button>
              </span>
            </form>
          </FormGroup>
        }
      </div>
    );
  }
}

export default reduxForm({
  form: 'search',
})(AdminListSearch);
