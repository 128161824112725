import { FormReducer, reducer as formReducer } from 'redux-form';
import * as actions from '../actions/';
import composeReducers from './composeReducers';

interface IFormState {
  [index: string]: {
    values: {
      [index: string]: any;
    };
  };
}

function setFieldIntoField(
  state: IFormState,
  form: string,
  field: string,
  file: File
) {
  return {
    ...state,
    [form]: {
      ...state[form],
      values: {
        ...state[form].values,
        [field]: file,
      },
    },
  };
}

function fileUploadsFormReducer(state: IFormState = {}, action: any) {
  switch (action.type) {
    case actions.SET_PENDING_UPLOAD_IN_FORM:
      const { form, field, file } = action.payload;
      return setFieldIntoField(state, form, field, file);
    default:
      return state;
  }
}

const myFormReducer = ((formReducer as any) as FormReducer).plugin({});

export default composeReducers([myFormReducer, fileUploadsFormReducer]);
