import * as React from 'react';
import { Column, ContentBox, ContentBoxHeader, Row } from 'roe';
import { APP_NAME } from '../constants';
import Nav from './nav/admin-sidebar';

interface IProps {
  children?: any;
}

export default class AdminPage extends React.PureComponent<IProps, void> {
  public render() {
    const { children } = this.props;

    return (
      <ContentBox>
        <ContentBoxHeader>
          <h5>
            {APP_NAME} Admin
          </h5>
        </ContentBoxHeader>
        <Row className="wrapper">
          <Column md={2}>
            <Nav />
          </Column>
          <Column md={10}>
            {children}
          </Column>
        </Row>
      </ContentBox>
    );
  }
}
