import { CollectionStore } from '@dabapps/redux-api-collections/dist/collections';
import { ItemStore } from '@dabapps/redux-api-collections/dist/items';
import { ResponsesReducerState } from '@dabapps/redux-api-collections/dist/requests';
import { List, Map } from 'immutable';
import { browserHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

import { ICollections } from '../collections';
import { IOptionsReducerState } from '../reducers/options';

import rootReducer from '../reducers';

interface IRoutingReducerState {
  locationBeforeTransitions: {
    pathname: string;
  };
}

export interface IStore {
  collections: CollectionStore<ICollections>;
  items: ItemStore<ICollections>;
  routing: IRoutingReducerState;
  responses: ResponsesReducerState;
  modals: List<React.ReactNode>;
  uiState: Map<string, string>;
  options: IOptionsReducerState;
}

const middlewares = [thunk, routerMiddleware(browserHistory)];

export default function configureStore(initialState?: IStore) {
  const createStoreWithMiddleware = applyMiddleware(...middlewares)(
    createStore
  );
  return createStoreWithMiddleware(rootReducer, initialState);
}
