import * as React from 'react';

import * as moment from 'moment';
import DatePicker from 'react-datepicker';
import {
  FORMAT_DATE_BACKEND,
  formatBackendDate,
  formatBackendDateTime,
  formatDate,
} from '../../../utils';
import FieldGroup from '../FieldGroup';
import {
  // tslint:disable-next-line:no-unused-variable
  IWrappedFieldProps,
  TWrappedFieldProps,
} from './types';

export interface IFieldDateProps {
  isDateTime?: boolean;
}

export class FieldDate extends React.PureComponent<
  React.HTMLProps<JSX.Element> & TWrappedFieldProps & IFieldDateProps,
  void
> {
  public render() {
    const { readOnly, config, value, errors } = this.props;
    return readOnly
      ? <FieldGroup config={config} errors={errors}>
          <span className="input">
            {formatDate(value)}
          </span>
        </FieldGroup>
      : <FieldGroup config={config} errors={errors}>
          <span className="input date-field">
            <DatePicker
              fixedHeight={true}
              showYearDropdown={true}
              dateFormat={FORMAT_DATE_BACKEND}
              selected={this.getValue()}
              onChange={event => this.handleChange(event)}
            />
          </span>
        </FieldGroup>;
  }

  private handleChange(value: moment.Moment | null) {
    const { onChange, isDateTime } = this.props;
    onChange(
      value &&
        ((isDateTime
          ? formatBackendDateTime(value)
          : formatBackendDate(value)) as any)
    );
  }

  private getValue() {
    const value = moment(this.props.value);
    return value.isValid() ? value : null;
  }
}

export default FieldDate;
