import * as React from 'react';

import PictureUpload from '../../../common/PictureUpload';
import FieldGroup from '../FieldGroup';
import {
  // tslint:disable-next-line:no-unused-variable
  IWrappedFieldProps,
  TWrappedFieldProps,
} from './types';

export const FieldImage = ({
  readOnly,
  config,
  errors,
  value,
  className,
  setUpload,
  formName,
  name,
}: React.HTMLProps<JSX.Element> & TWrappedFieldProps) => {
  return readOnly
    ? <FieldGroup config={config} errors={errors}>
        <span className="input">
          <img
            className="image-field"
            src={value || '/static/images/placeholder-image.png'}
          />
        </span>
      </FieldGroup>
    : <FieldGroup config={config} errors={errors}>
        <PictureUpload
          className="input image-field"
          src={value && (value.preview || value)}
          display={(src: any) =>
            <img src={src || '/static/images/placeholder-image.png'} />}
          setPendingUpload={setUpload}
          onSubmit={() => null}
        />
      </FieldGroup>;
};

export default FieldImage;
