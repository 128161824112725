import {
  recordArray,
  RecordWithConstructor,
  SimpleRecord,
} from '@dabapps/simple-records';
import {
  IQualificationSubscription,
  IQualificationSubscriptionInput,
  QualificationSubscriptionRecord,
} from './qualification-subscription';

type TInstitutionType = 'CENTRE' | 'SCHOOL' | 'COLLEGE' | 'UNIVERSITY';
export type TTerminologyType =
  | 'DEFAULT'
  | 'APPRENTICESHIPS'
  | 'VTCT TLQ'
  | 'OCR TECHNICALS';

export type IInstitutionList = Readonly<{
  id: string;
  type: TInstitutionType;
  terminology_type: TTerminologyType;
  name: string;
  centre_number: string;
}>;

interface IInstitutionShared extends IInstitutionList {
  street_address_1: string;
  street_address_2: string;
  street_address_3: string;
  locality: string;
  region: string;
  postal_code: string;
  phone_number: string;
  fax_number: string;
  dfes_number: string;
  dfes_lacode: string;
  users: ReadonlyArray<string>;
}

export type IInstitutionInput = Readonly<
  IInstitutionShared & {
    qualification_subscriptions: ReadonlyArray<
      Partial<IQualificationSubscriptionInput>
    >;
  }
>;
export type IInstitution = Readonly<
  IInstitutionShared & {
    qualification_subscriptions: ReadonlyArray<IQualificationSubscription>;
  }
>;

export const InstitutionRecord = RecordWithConstructor<
  IInstitutionInput,
  IInstitution
>(
  {
    centre_number: '',
    dfes_lacode: '',
    dfes_number: '',
    fax_number: '',
    id: '',
    locality: '',
    name: '',
    phone_number: '',
    postal_code: '',
    region: '',
    street_address_1: '',
    street_address_2: '',
    street_address_3: '',
    type: 'CENTRE',
    terminology_type: 'DEFAULT',
    users: [],
    qualification_subscriptions: [],
  },
  input => {
    return {
      ...input,
      qualification_subscriptions: recordArray(
        input.qualification_subscriptions,
        QualificationSubscriptionRecord
      ),
    };
  }
);

export const InstitutionListRecord = SimpleRecord<IInstitutionList>({
  centre_number: '',
  id: '',
  name: '',
  type: 'CENTRE',
  terminology_type: 'DEFAULT',
});

export type ITerminologyOption = Readonly<{
  id: string;
  name: string;
}>;

export const TerminologyOption = SimpleRecord<ITerminologyOption>({
  id: '',
  name: '',
});
