import * as React from 'react';
import * as Dropzone from 'react-dropzone';
import { Button } from 'roe';

import { ACTIVE_STYLE, REJECT_STYLE, STYLE } from './dropzone';

export interface IProps {
  className?: string;
  display: (file?: File | string) => React.ReactNode;
  file?: File;
  onSubmit?: () => void;
  setPendingUpload?: (file?: File) => void;
  src?: string;
}

export default class FileUpload extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
    this.onOpenClick = this.onOpenClick.bind(this);
    this.submit = this.submit.bind(this);
  }

  public render() {
    const { src, file, className } = this.props;
    return (
      <div className={className}>
        <Dropzone
          ref="dropzone"
          multiple={false}
          disableClick={true}
          onDrop={this.onDrop}
          style={STYLE}
          activeStyle={ACTIVE_STYLE}
          rejectStyle={REJECT_STYLE}
        >
          {this.props.display(file || src)}
        </Dropzone>

        <Button
          type="button"
          className="primary"
          onClick={this.onOpenClick.bind(this)}
        >
          Select a {src || file ? 'new' : ''} file
        </Button>

        {file &&
          <Button type="button" className="primary" onClick={this.submit}>
            Upload File
          </Button>}
      </div>
    );
  }

  private onOpenClick() {
    (this.refs.dropzone as any).open();
  }

  private onDrop(files: FileList) {
    const { setPendingUpload } = this.props;
    if (files.length && setPendingUpload) {
      setPendingUpload(files[0]);
    }
  }

  private submit() {
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit();
    }
  }
}
