import * as React from 'react';
import { APP_NAME } from '../../constants';
import Tab from './tab';

const AdminSidebar: React.SFC<React.HTMLProps<HTMLDivElement>> = () =>
  <div className="admin-nav">
    <nav className="admin-nav-primary">
      <ul>
        <Tab link="/" matches="/">
          Home
        </Tab>
        <Tab link="/adminusers/" matches="/adminusers/">
          {APP_NAME} Users
        </Tab>
        <Tab link="/users/" matches="/users/">
          Users
        </Tab>
        <Tab link="/institutions/" matches="/institutions/">
          Institutions
        </Tab>
        <Tab link="/productsubscriptions/" matches="/productsubscriptions/">
          Product Subscriptions
        </Tab>
        <Tab
          link="/qualificationsubscriptions/"
          matches="/qualificationsubscriptions/"
        >
          Qualification Subscriptions
        </Tab>
      </ul>
    </nav>
  </div>;

export default AdminSidebar;
