export const STYLE = {
  borderColor: '#ddd',
  borderRadius: 2,
  borderStyle: 'dashed',
  borderWidth: 2,
  marginBottom: 8,
  padding: 8,
};

export const ACTIVE_STYLE = {
  ...STYLE,
  backgroundColor: '#eee',
  borderStyle: 'solid',
};

export const REJECT_STYLE = {
  ...STYLE,
  backgroundColor: '#fdd',
  borderStyle: 'solid',
};
