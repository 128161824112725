import * as React from 'react';
import { TableCell, TableRow } from 'roe';

import { IColumn } from '../types';
import AdminItemField from './AdminItemField';

export interface IProps<T> extends React.HTMLProps<JSX.Element> {
  item: T;
  field: IColumn<T>;
}

export default class AdminDetaiItem<T> extends React.PureComponent<
  IProps<T>,
  void
> {
  public render() {
    const { field, item } = this.props;
    return (
      <TableRow>
        <TableCell>
          <strong>
            {field.display_name}
          </strong>
        </TableCell>
        <TableCell>
          <AdminItemField item={item} field={field} removeItem={() => null} />
        </TableCell>
      </TableRow>
    );
  }
}
