import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { IStore } from '../../store/configureStore';

interface IExternalProps {
  link: string;
  matches?: string;
}

interface IProps extends IExternalProps {
  pathname: string;
}

export class Tab extends React.PureComponent<IProps, void> {
  public render() {
    const { link, children } = this.props;

    return (
      <li className="admin-tab">
        <Link
          to={link}
          className={classNames(
            'nav-primary-link',
            this.pathMatches() && 'active'
          )}
        >
          {children}
        </Link>
      </li>
    );
  }

  private pathMatches() {
    const { link, pathname, matches } = this.props;
    if (!pathname) {
      return false;
    }

    if (pathname === link) {
      return true;
    }

    if (!matches) {
      return false;
    }

    return typeof matches === 'string'
      ? pathname === matches
      : pathname.match(matches);
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps) {
  return {
    pathname: state.routing.locationBeforeTransitions.pathname,
  };
}

export default connect(mapStateToProps)(Tab);
