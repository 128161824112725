import * as React from 'react';

import {
  CollectionOptions,
  GET_COLLECTION,
} from '@dabapps/redux-api-collections/dist/collections';
import {
  GET_ITEM,
  getItemByName,
  UPDATE_ITEM,
} from '@dabapps/redux-api-collections/dist/items';
import {
  hasFailed,
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { Dict } from '@dabapps/simple-records';
import { connect } from 'react-redux';
import { Button, Column, Row } from 'roe';
import { setUIState } from '../../actions/';
import { AdminEditCreate } from '../../chadmin';
import { IItemConfig } from '../../chadmin';
import { CollectionType, default as Collections } from '../../collections';
import { getFormErrors } from '../../responses';
import {
  getInstitutionsChoices,
  SUBSCRIPTIONS,
} from '../../selectors/subscriptions';
import { IStore } from '../../store/configureStore';
import { IProductSubscription } from '../../store/data-types/product-subscription';
import {
  DETAIL_FIELD_OPTIONS,
  DETAIL_FIELDS,
  PRODUCT_SUBSCRIPTIONS,
} from './config';

interface IExternalProps {
  params: {
    id: string;
  };
}

interface IProps extends IExternalProps {
  productSubscription: IProductSubscription;
  isEditing: boolean;
  loading: boolean;
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
  itemIsPending: boolean;
  errors: Dict<ReadonlyArray<string>> | undefined;
  detailFieldOptions: IItemConfig;
  setEditing(value: boolean): void;
  getItem(type: CollectionType, id: string): void;
  getAllCollection(
    type: CollectionType,
    options: CollectionOptions,
    tag: string
  ): void;
  updateItem(
    type: CollectionType,
    id: string,
    data: Partial<IProductSubscription>
  ): void;
}

export class ProductSubscriptionsDetail extends React.PureComponent<
  IProps,
  void
> {
  public constructor(props: IProps) {
    super(props);

    this.toggleEdit = this.toggleEdit.bind(this);
    this.update = this.update.bind(this);
  }

  public componentDidMount() {
    this.props.getItem(PRODUCT_SUBSCRIPTIONS, this.props.params.id);
    this.props.getAllCollection('admin/institutions', {}, SUBSCRIPTIONS);
  }

  public render() {
    const {
      loading,
      productSubscription,
      detailFieldOptions,
      isEditing,
      itemHasFailed,
      itemHasSucceeded,
      itemIsPending,
      errors,
    } = this.props;

    return (
      <Row>
        <Column>
          <AdminEditCreate
            readOnly={!isEditing}
            item={productSubscription}
            fields={DETAIL_FIELDS}
            itemOptions={detailFieldOptions}
            formName="product-subscriptions-detail"
            patchItem={this.update}
            setPendingUploadInForm={console.log}
            itemErrors={errors}
            loading={loading}
            itemHasFailed={itemHasFailed}
            itemHasSucceeded={itemHasSucceeded}
            itemIsPending={itemIsPending}
          />
          <Button onClick={this.toggleEdit}>
            {isEditing ? 'Stop Editing' : 'Edit'}
          </Button>
        </Column>
      </Row>
    );
  }

  private toggleEdit(): void {
    const { isEditing, setEditing } = this.props;
    return isEditing ? setEditing(false) : setEditing(true);
  }

  private update(data: Partial<IProductSubscription>): void {
    this.props.updateItem(PRODUCT_SUBSCRIPTIONS, this.props.params.id, data);
  }
}

const IS_PRODUCT_SUBSCRIPTIONS_DETAIL_EDITING =
  'IS_PRODUCT_SUBSCRIPTIONS_DETAIL_EDITING';

function mapStateToProps(state: IStore, props: IExternalProps) {
  const { items, responses, uiState } = state;
  const choices = getInstitutionsChoices(state);

  const detailFieldOptions: IItemConfig = {
    ...DETAIL_FIELD_OPTIONS,
    institution: {
      label: 'Institution',
      type: 'text',
      choices,
    },
  };

  const loading =
    isPending(responses, GET_ITEM, PRODUCT_SUBSCRIPTIONS) ||
    isPending(responses, GET_COLLECTION, SUBSCRIPTIONS);

  return {
    errors: getFormErrors(responses, UPDATE_ITEM, PRODUCT_SUBSCRIPTIONS),
    isEditing: uiState.get(IS_PRODUCT_SUBSCRIPTIONS_DETAIL_EDITING, false),
    itemHasFailed: hasFailed(responses, UPDATE_ITEM, PRODUCT_SUBSCRIPTIONS),
    itemHasSucceeded: hasSucceeded(
      responses,
      UPDATE_ITEM,
      PRODUCT_SUBSCRIPTIONS
    ),
    itemIsPending: isPending(responses, UPDATE_ITEM, PRODUCT_SUBSCRIPTIONS),
    productSubscription: getItemByName(items, PRODUCT_SUBSCRIPTIONS),
    loading,
    detailFieldOptions,
  };
}

export default connect(mapStateToProps, {
  updateItem: Collections.actions.updateItem,
  getItem: Collections.actions.getItem,
  setEditing: isEditing =>
    setUIState(IS_PRODUCT_SUBSCRIPTIONS_DETAIL_EDITING, isEditing),
  getAllCollection: Collections.actions.getAllCollection,
})(ProductSubscriptionsDetail);
