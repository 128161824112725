import { AxiosPromise } from 'axios';
import * as React from 'react';
import { formatDate } from '../../utils';
import SimpleTable, { IColumnData } from '../simple-table';

import {
  CollectionOptions,
  DELETE_FROM_COLLECTION,
  GET_COLLECTION,
} from '@dabapps/redux-api-collections/dist/collections';
import {
  GET_ITEM,
  getItemByName,
  UPDATE_ITEM,
} from '@dabapps/redux-api-collections/dist/items';
import {
  hasFailed,
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { Dict } from '@dabapps/simple-records';
import { connect } from 'react-redux';
import { Button, Column, Row } from 'roe';
import { setUIState } from '../../actions/';
import { AdminEditCreate } from '../../chadmin';
import { IItemConfig } from '../../chadmin';
import { CollectionType, default as Collections } from '../../collections';
import { getFormErrors } from '../../responses';
import {
  getTerminologyChoices,
  getUsersChoices,
} from '../../selectors/institutions';
import { formatQualificationDisplayName } from '../../selectors/subscriptions';
import { IStore } from '../../store/configureStore';
import { IInstitution } from '../../store/data-types/institution';
import { IQualificationSubscription } from '../../store/data-types/qualification-subscription';
import { QUALIFICATION_SUBSCRIPTIONS } from '../qualification-subscriptions/config';
import QualificationSubscriptionsQuickAdd from '../qualification-subscriptions/qualification-subscriptions-quick-add';
import { USERS } from '../users/config';
import {
  DETAIL_FIELD_OPTIONS,
  DETAIL_FIELDS,
  INSTITUTIONS,
  TERMINOLOGY_OPTIONS_ROUTE,
} from './config';

interface IExternalProps {
  params: {
    id: string;
  };
}

interface IProps extends IExternalProps {
  isEditing: boolean;
  detailFieldOptions: IItemConfig;
  institution: IInstitution | null;
  loading: boolean;
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
  itemIsPending: boolean;
  errors: Dict<ReadonlyArray<string>> | undefined;
  setEditing(value: boolean): void;
  getAllCollection(
    type: CollectionType,
    options: CollectionOptions,
    tag: string
  ): void;
  deleteItem(type: CollectionType, id: string): AxiosPromise;
  getItem(type: CollectionType, id: string): void;
  updateItem(
    type: CollectionType,
    id: string,
    data: Partial<IInstitution>
  ): void;
}

const FORM_NAME = 'institutions-detail';

export class InstitutionsDetail extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);

    this.toggleEdit = this.toggleEdit.bind(this);
    this.update = this.update.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.getHeaders = this.getHeaders.bind(this);
    this.getItem = this.getItem.bind(this);
  }

  public componentDidMount() {
    this.getItem();
    this.props.getAllCollection(USERS, {}, INSTITUTIONS);
    this.props.getAllCollection(TERMINOLOGY_OPTIONS_ROUTE, {}, INSTITUTIONS);
  }

  public render() {
    const {
      loading,
      institution,
      isEditing,
      itemHasFailed,
      itemHasSucceeded,
      itemIsPending,
      errors,
      detailFieldOptions,
    } = this.props;

    if (!institution) {
      return <span>Loading...</span>;
    }

    return (
      <div>
        <Row>
          <Column>
            <h3>
              {this.getTitle()}
            </h3>
            <AdminEditCreate
              readOnly={!isEditing}
              item={institution}
              fields={DETAIL_FIELDS}
              itemOptions={detailFieldOptions}
              formName={FORM_NAME}
              patchItem={this.update}
              setPendingUploadInForm={console.log}
              itemErrors={errors}
              loading={loading}
              itemHasFailed={itemHasFailed}
              itemHasSucceeded={itemHasSucceeded}
              itemIsPending={itemIsPending}
            />
            <Button
              className="primary float-right margin-vertical-base"
              onClick={this.toggleEdit}
            >
              {isEditing ? 'Stop Editing' : 'Edit'}
            </Button>
            <h3>Qualification Subscriptions</h3>
            {!isEditing &&
              <QualificationSubscriptionsQuickAdd
                institution={institution.id}
                onAdd={this.getItem}
              />}
            <SimpleTable
              loading={loading}
              items={institution.qualification_subscriptions}
              headers={this.getHeaders()}
            />
          </Column>
        </Row>
      </div>
    );
  }

  private toggleEdit(): void {
    const { isEditing, setEditing } = this.props;
    return isEditing ? setEditing(false) : setEditing(true);
  }

  private update(data: Partial<IInstitution>): void {
    this.props.updateItem(INSTITUTIONS, this.props.params.id, data);
  }

  private getHeaders(): Array<IColumnData<IQualificationSubscription>> {
    return [
      {
        content: data => formatDate(data.active_from),
        headerLabel: 'Active from',
        key: 'active_from',
        sortable: false,
      },
      {
        content: data => formatDate(data.active_to),
        headerLabel: 'Active to',
        key: 'active_to',
        sortable: false,
      },
      {
        content: data => data.name,
        headerLabel: 'Name',
        key: 'name',
        sortable: false,
      },
      {
        content: data =>
          formatQualificationDisplayName(
            data.qualification_name,
            data.qualification_external_id
          ),
        headerLabel: 'Qualification Name',
        key: 'qualification_name',
        sortable: false,
      },
      {
        content: data =>
          <a onClick={this.onRemove.bind(this, data.id)}>remove</a>,
        headerLabel: '',
        key: 'remove',
        sortable: false,
      },
    ];
  }

  private onRemove(id: string) {
    if (
      window.confirm(
        'Are you sure you want to remove this subscription? This cannot be undone'
      )
    ) {
      this.props.deleteItem(QUALIFICATION_SUBSCRIPTIONS, id).then(this.getItem);
    }
  }

  private getItem() {
    this.props.getItem(INSTITUTIONS, this.props.params.id);
  }

  private getTitle() {
    const { isEditing, institution } = this.props;
    return `${isEditing ? 'Edit ' : ''}Institution${institution
      ? `: ${institution.name}`
      : ''}`;
  }
}

const IS_INSTITUTION_DETAIL_EDITING = 'IS_INSTITUTION_DETAIL_EDITING';

function mapStateToProps(state: IStore, props: IExternalProps) {
  const { items, responses, uiState } = state;

  const usersChoices = getUsersChoices(state);

  const terminologyChoices = getTerminologyChoices(state);

  const loading =
    isPending(responses, GET_ITEM, INSTITUTIONS) ||
    isPending(responses, GET_COLLECTION, USERS) ||
    isPending(responses, DELETE_FROM_COLLECTION, QUALIFICATION_SUBSCRIPTIONS);

  const detailFieldOptions: IItemConfig = {
    ...DETAIL_FIELD_OPTIONS,
    users: {
      label: 'Users',
      type: 'many',
      choices: usersChoices,
      clearable: false,
      fullWidth: true,
    },
    terminology_type: {
      choices: terminologyChoices,
      label: 'Terminology *',
      type: 'text',
    },
  };

  return {
    detailFieldOptions,
    errors: getFormErrors(responses, UPDATE_ITEM, INSTITUTIONS),
    institution: getItemByName(items, INSTITUTIONS),
    isEditing: uiState.get(IS_INSTITUTION_DETAIL_EDITING, false),
    loading,
    itemHasFailed: hasFailed(responses, UPDATE_ITEM, INSTITUTIONS),
    itemHasSucceeded: hasSucceeded(responses, UPDATE_ITEM, INSTITUTIONS),
    itemIsPending: isPending(responses, UPDATE_ITEM, INSTITUTIONS),
  };
}

export default connect(mapStateToProps, {
  updateItem: Collections.actions.updateItem,
  getItem: Collections.actions.getItem,
  getAllCollection: Collections.actions.getAllCollection,
  setEditing: isEditing => setUIState(IS_INSTITUTION_DETAIL_EDITING, isEditing),
  deleteItem: Collections.actions.deleteItem,
})(InstitutionsDetail as any); // FIXME
