import {
  hasFailed,
  ResponsesReducerState,
} from '@dabapps/redux-api-collections/dist/requests';
import { AsyncActionSet } from '@dabapps/redux-api-collections/dist/requests';
import { Dict } from '@dabapps/simple-records';
import { FormErrors } from './utils';

export function getErrorData(
  state: ResponsesReducerState,
  actionSet: AsyncActionSet,
  tag?: string
): Dict<any> | ReadonlyArray<any> | string | number | null | undefined {
  if (hasFailed(state, actionSet, tag)) {
    return state[actionSet.REQUEST][tag || ''].data;
  }
}

export function getFormErrors(
  state: ResponsesReducerState,
  actionSet: AsyncActionSet,
  tag?: string
): FormErrors | undefined {
  const data = getErrorData(state, actionSet, tag);
  if (typeof data === 'object' && data !== null) {
    // If we're expecting form errors, and we find an object, it's very, very unlikely to be anything other than FormErrors
    return data as any;
  }
  return undefined;
}
