import { RecordWithConstructor } from '@dabapps/simple-records';
import * as moment from 'moment';

interface IProductSubscriptionShared {
  id: string;
  name: string;
  product: 'MANAGE' | 'ANNOTATE' | 'MANAGE_AWARDING_BODIES';
  institution: string;
}
// tslint:disable-next-line:no-unused-variable
type IProductSubscriptionInput = Readonly<
  IProductSubscriptionShared & {
    active_from: string | Date;
    active_to: string | Date;
  }
>;
export type IProductSubscription = Readonly<
  IProductSubscriptionShared & {
    active_from: moment.Moment;
    active_to: moment.Moment;
  }
>;

export const ProductSubscriptionRecord = RecordWithConstructor<
  IProductSubscriptionInput,
  IProductSubscription
>(
  {
    active_from: new Date(),
    active_to: new Date(),
    id: '',
    institution: '',
    name: '',
    product: 'MANAGE',
  },
  input => {
    return {
      ...input,
      active_from: moment.utc(input.active_from),
      active_to: moment.utc(input.active_to),
    };
  }
);
