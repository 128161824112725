import * as _ from 'underscore';

export default (
  reducers: Array<(state: any, action: any) => any>,
  initialState = {}
) => (state = initialState, action: any) =>
  _.compose.apply(
    null,
    reducers.map(reducer => (mState: any) => reducer(mState, action))
  )(state);
