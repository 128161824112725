import * as React from 'react';

import { IItemConfig } from '../../chadmin';
import { TColumns } from '../../chadmin';
import { CollectionType } from '../../collections';
import { IInstitution } from '../../store/data-types/institution';

export const INSTITUTIONS: CollectionType = 'admin/institutions';

const INSTITUTION_DETAIL_ROUTE = '${id}';

export const TERMINOLOGY_OPTIONS_ROUTE = 'admin/terminology-options';

const TYPE_CHOICES = [
  {
    display_name: 'Centre',
    value: 'CENTRE',
  },
  {
    display_name: 'School',
    value: 'SCHOOL',
  },
  {
    display_name: 'College',
    value: 'COLLEGE',
  },
  {
    display_name: 'University',
    value: 'UNIVERSITY',
  },
];

const TYPE_VALUE_MAP = {
  CENTRE: 'Centre',
  COLLEGE: 'College',
  SCHOOL: 'School',
  UNIVERSITY: 'University',
};

export const TERMINOLOGY_TYPE_VALUE_MAP: Readonly<{ [k: string]: string }> = {
  DEFAULT: 'Default',
  APPRENTICESHIPS: 'Apprenticeships',
  'VTCT TLQ': 'VTCT TLQ',
  'OCR TECHNICALS': 'OCR Technicals',
};

// list
export const LIST_COLUMNS: TColumns<IInstitution> = [
  { name: 'name', display_name: 'Name', type: 'text', sortable: true },
  {
    name: 'centre_number',
    display_name: 'Centre Number',
    type: 'text',
    sortable: true,
  },
  {
    name: 'type',
    display_name: 'Type',
    type: 'custom',
    customItemHandler: (institution: IInstitution) =>
      <span>
        {TYPE_VALUE_MAP[institution.type]}
      </span>,
    sortable: true,
  },
  {
    name: 'terminology_type',
    display_name: 'Terminology',
    type: 'custom',
    customItemHandler: (institution: IInstitution) =>
      TERMINOLOGY_TYPE_VALUE_MAP[institution.terminology_type],
    sortable: true,
  },
  {
    name: 'actions',
    display_name: 'Actions',
    type: 'detail',
    link_text: 'Manage',
    route: INSTITUTION_DETAIL_ROUTE,
  },
  { name: 'id', display_name: 'Delete', type: 'remove', sortable: false },
];

// detail
export const DETAIL_FIELDS = [
  'name',
  'centre_number',
  'type',
  'terminology_type',
  'street_address_1',
  'street_address_2',
  'street_address_3',
  'postal_code',
  'locality',
  'region',
  'phone_number',
  'fax_number',
  'dfes_number',
  'dfes_lacode',
  'members_can_create_other_members',
  'users',
];

export const DETAIL_FIELD_OPTIONS: IItemConfig = {
  centre_number: {
    label: 'Centre Number * ',
    max_length: 255,
    type: 'text',
  },
  dfes_lacode: {
    label: 'DFES Lacode',
    max_length: 255,
    type: 'text',
  },
  dfes_number: {
    label: 'DFES Number',
    max_length: 255,
    type: 'text',
  },
  fax_number: {
    label: 'Fax Number',
    max_length: 255,
    type: 'text',
  },
  locality: {
    label: 'Locality',
    max_length: 255,
    type: 'text',
  },
  name: {
    label: 'Name * ',
    max_length: 255,
    type: 'text',
  },
  phone_number: {
    label: 'Phone Number',
    max_length: 255,
    type: 'text',
  },
  postal_code: {
    label: 'Post Code',
    max_length: 25,
    type: 'text',
  },
  region: {
    label: 'Region',
    max_length: 255,
    type: 'text',
  },
  street_address_1: {
    label: 'Street Address 1 * ',
    max_length: 255,
    type: 'text',
  },
  street_address_2: {
    label: 'Street Address 2',
    max_length: 255,
    type: 'text',
  },
  street_address_3: {
    label: 'Street Address 3',
    max_length: 255,
    type: 'text',
  },
  type: {
    choices: TYPE_CHOICES,
    label: 'Type *',
    type: 'text',
  },
  members_can_create_other_members: {
    label: 'Members Can Create Other Members',
    type: 'boolean',
  },
};
