import * as React from 'react';

import FieldGroup from '../FieldGroup';
import {
  // tslint:disable-next-line:no-unused-variable
  IWrappedFieldProps,
  TWrappedFieldProps,
} from './types';

export const FieldNumber = ({
  readOnly,
  config,
  name,
  errors,
  value,
  onChange,
  className,
}: React.HTMLProps<JSX.Element> & TWrappedFieldProps) => {
  return readOnly
    ? <FieldGroup config={config} errors={errors}>
        <span className="input">
          {value}
        </span>
      </FieldGroup>
    : <FieldGroup config={config} errors={errors}>
        <input
          type="number"
          name={name}
          step={config.step}
          placeholder={config.placeholder}
          onChange={event => onChange(event)}
          value={value}
        />
      </FieldGroup>;
};

export default FieldNumber;
