import { CollectionOptions } from '@dabapps/redux-api-collections/dist/collections';
import { Map } from 'immutable';
import { IAction } from '../actions/index';

import * as optionsActions from '../actions/options';

export type IOptionsReducerState = Map<string, CollectionOptions>;

function transformOrdering(
  orderingKey: string,
  options: CollectionOptions = {}
): CollectionOptions {
  const { ordering, reverseOrdering } = options;
  // set ordering if not set, or set on different field
  if (!ordering || ordering.indexOf(orderingKey) === -1) {
    return {
      ...options,
      ordering: orderingKey,
      reverseOrdering: false,
    };
  }

  // reverse if ordering is set
  if (ordering.indexOf(orderingKey) === 0 && !reverseOrdering) {
    return {
      ...options,
      reverseOrdering: true,
    };
  }

  // ordering must be set, and reversed - unset it
  const {
    ordering: orderingToRemove,
    reverseOrdering: reverseOrderingToRemove,
    ...otherOptions,
  } = options;

  return otherOptions;
}

function transformSearch(
  searchTerm: string,
  options: CollectionOptions = {}
): CollectionOptions {
  const { search, ...otherOptions } = options;

  if (!searchTerm) {
    // we don't want to filter at all
    return otherOptions;
  }

  return {
    ...options,
    search: searchTerm,
  };
}

export default function(
  state: IOptionsReducerState = Map<string, CollectionOptions>(),
  action: IAction<optionsActions.IOptionsActionPayload, void>
) {
  const { type, payload = { key: '', value: '' } } = action;

  switch (type) {
    case optionsActions.SET_ORDERING:
      return state.update(payload.key, options =>
        transformOrdering(payload.value as string, options)
      );
    case optionsActions.SET_SEARCH:
      return state.update(payload.key, options =>
        transformSearch(payload.value as string, options)
      );
    case optionsActions.SET_PAGE:
      return state.update(payload.key, (options = {}) => ({
        ...options,
        page: payload.value as number,
      }));
    case optionsActions.CLEAR_OPTIONS:
      return state.delete(payload.key);
    default:
      return state;
  }
}
