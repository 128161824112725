import * as React from 'react';

import FieldGroup from '../FieldGroup';
import {
  // tslint:disable-next-line:no-unused-variable
  IWrappedFieldProps,
  TWrappedFieldProps,
} from './types';

const FieldStatic = ({
  config,
  className,
  errors,
}: React.HTMLProps<JSX.Element> & TWrappedFieldProps) =>
  <FieldGroup config={config} errors={errors}>
    <span className="input">
      {config.text}
    </span>
  </FieldGroup>;

export default FieldStatic;
