import { IAction } from './index';

export interface IOptionsActionPayload {
  key: string;
  value: string | number;
}

export const CLEAR_OPTIONS = 'CLEAR_OPTIONS';
export function clearOptions(
  key: string
): IAction<IOptionsActionPayload, void> {
  return {
    payload: { key, value: '' },
    type: CLEAR_OPTIONS,
  };
}

export const SET_ORDERING = 'SET_ORDERING';
export function setOrdering(
  key: string,
  orderingKey: string
): IAction<IOptionsActionPayload, void> {
  return {
    payload: { key, value: orderingKey },
    type: SET_ORDERING,
  };
}

export const SET_SEARCH = 'SET_SEARCH';
export function setSearch(
  key: string,
  searchTerm: string
): IAction<IOptionsActionPayload, void> {
  return {
    payload: { key, value: searchTerm },
    type: SET_SEARCH,
  };
}

export const SET_PAGE = 'SET_PAGE';
export function setPage(
  key: string,
  page: number
): IAction<IOptionsActionPayload, void> {
  return {
    payload: { key, value: page },
    type: SET_PAGE,
  };
}
