import * as React from 'react';
import { Alert } from 'roe';

const AdditionalSuccessMessage = () =>
  <Alert className="warning">
    <strong>
      <p>Users must be assigned to an institution.</p>
    </strong>
  </Alert>;

export default AdditionalSuccessMessage;
