import * as moment from 'moment';
import * as _ from 'underscore';
import { FAILED, PENDING, SUCCESSFUL } from './responseStates';
import { FieldErrors, FormErrors, TResponse } from './types';

export const FORMAT_DATE = 'Do MMM YYYY';
export const FORMAT_TIME = 'HH:mm';
export const FORMAT_DATE_TIME = FORMAT_DATE.concat(' [at] ').concat(
  FORMAT_TIME
);
export const FORMAT_DATE_BACKEND = 'YYYY-MM-DD';
export const FORMAT_DATE_TIME_BACKEND = FORMAT_DATE_BACKEND.concat('THH:mm:ss');
export const FORMAT_DATE_TIME_ADMIN = FORMAT_DATE_BACKEND.concat(' ').concat(
  FORMAT_TIME
);

export function formatDateTime(
  date: string,
  dateNotSupliedDefault?: string,
  format = FORMAT_DATE_TIME
) {
  if (!date) {
    return dateNotSupliedDefault || 'unknown';
  }
  return moment(date).format(format);
}

export function formatDate(date: string) {
  return moment(date).format(FORMAT_DATE);
}

export function formatBackendDate(date: string | moment.Moment) {
  return moment(date).format(FORMAT_DATE_BACKEND);
}

export function formatBackendDateTime(datetime: string | moment.Moment) {
  return moment(datetime).format(FORMAT_DATE_TIME_BACKEND);
}

export function parsePropertyString(str: string): ReadonlyArray<string> {
  return str.split('.');
}

export function parseObjectPropertyString(obj: any, stringFormat: string) {
  return stringFormat.replace(/\$\{(.*?)\}/gi, (property, propertyName) =>
    getIn(obj, propertyName, '--')
  );
}

export function isPending(response?: TResponse) {
  return response && response.state === PENDING;
}

export function hasFailed(response?: TResponse) {
  return response && response.state === FAILED;
}

export function hasSucceeded(response?: TResponse) {
  return response && response.state === SUCCESSFUL;
}

export function getFieldErrors(
  errors: FormErrors | undefined,
  fieldName?: string
): FieldErrors | undefined {
  if (!fieldName) {
    return;
  }
  if (!errors) {
    return errors;
  }

  return getIn(errors, fieldName);
}

export function getInByPath(
  item: any,
  path: ReadonlyArray<string>,
  defaultValue: any
): any {
  if (item === undefined || item === null) {
    return defaultValue;
  }
  if (path.length === 0) {
    return item;
  }
  return getInByPath(item[_.first(path) || ''], _.rest(path), defaultValue);
}

export function getIn(item: any, fieldName: string, defaultValue?: any): any {
  if (!item) {
    return defaultValue;
  }
  return getInByPath(item, parsePropertyString(fieldName), defaultValue);
}
