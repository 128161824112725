import * as React from 'react';

import {
  GET_ITEM,
  getItemByName,
  UPDATE_ITEM,
} from '@dabapps/redux-api-collections/dist/items';
import {
  hasFailed,
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { Dict } from '@dabapps/simple-records';
import { connect } from 'react-redux';
import { Button, Column, Row } from 'roe';
import { setUIState } from '../../actions/';
import { AdminEditCreate } from '../../chadmin';
import { CollectionType, default as Collections } from '../../collections';
import { getFormErrors } from '../../responses';
import { IStore } from '../../store/configureStore';
import { IUser } from '../../store/data-types/user';
import { encodeFiles } from '../../utils';
import { DETAIL_FIELD_OPTIONS, DETAIL_FIELDS, USERS } from './config';

interface IExternalProps {
  params: {
    id: string;
  };
}

interface IProps extends IExternalProps {
  isEditing: boolean;
  user: IUser;
  loading: boolean;
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
  itemIsPending: boolean;
  errors: Dict<ReadonlyArray<string>> | undefined;
  getItem(type: CollectionType, id: string): void;
  setEditing(value: boolean): void;
  updateItem(type: CollectionType, id: string, data: Partial<IUser>): void;
}

const FORM_NAME = 'user-detail';

export class UsersDetail extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.update = this.update.bind(this);
  }

  public componentDidMount() {
    this.props.getItem(USERS, this.props.params.id);
  }

  public render() {
    const {
      loading,
      user,
      isEditing,
      itemHasFailed,
      itemHasSucceeded,
      itemIsPending,
      errors,
    } = this.props;

    return (
      <Row>
        <Column>
          <h3>Edit User</h3>
          <AdminEditCreate
            readOnly={!isEditing}
            item={user}
            fields={DETAIL_FIELDS}
            itemOptions={DETAIL_FIELD_OPTIONS}
            formName={FORM_NAME}
            patchItem={this.update}
            setPendingUploadInForm={console.log}
            itemErrors={errors}
            loading={loading}
            itemIsPending={itemIsPending}
            itemHasFailed={itemHasFailed}
            itemHasSucceeded={itemHasSucceeded}
          />
          <Button
            className="button primary float-right margin-vertical-base"
            onClick={this.toggleEdit}
          >
            {isEditing ? 'Stop Editing' : 'Edit'}
          </Button>
        </Column>
      </Row>
    );
  }

  private toggleEdit(): void {
    const { isEditing, setEditing } = this.props;
    return isEditing ? setEditing(false) : setEditing(true);
  }

  private update(data: Partial<IUser>): void {
    encodeFiles(data).then(encoded =>
      this.props.updateItem(USERS, this.props.params.id, encoded)
    );
  }
}

const IS_USER_DETAIL_EDITING = 'IS_USER_DETAIL_EDITING';

function mapStateToProps(
  { items, responses, uiState }: IStore,
  props: IExternalProps
) {
  return {
    errors: getFormErrors(responses, UPDATE_ITEM, USERS),
    isEditing: uiState.get(IS_USER_DETAIL_EDITING, false),
    itemHasFailed: hasFailed(responses, UPDATE_ITEM, USERS),
    itemHasSucceeded: hasSucceeded(responses, UPDATE_ITEM, USERS),
    itemIsPending: isPending(responses, UPDATE_ITEM, USERS),
    loading: isPending(responses, GET_ITEM, USERS),
    user: getItemByName(items, USERS),
  };
}

export default connect(mapStateToProps, {
  updateItem: Collections.actions.updateItem,
  getItem: Collections.actions.getItem,
  setEditing: isEditing => setUIState(IS_USER_DETAIL_EDITING, isEditing),
})(UsersDetail);
