import * as React from 'react';

import FieldGroup from '../FieldGroup';
import {
  // tslint:disable-next-line:no-unused-variable
  IWrappedFieldProps,
  TWrappedFieldProps,
} from './types';

export const FieldBoolean = ({
  readOnly,
  config,
  name,
  errors,
  value,
  onChange,
  className,
}: React.HTMLProps<JSX.Element> & TWrappedFieldProps) => {
  return (
    <FieldGroup config={config} errors={errors}>
      <input
        type="checkbox"
        checked={value}
        name={name}
        onChange={onChange}
        disabled={readOnly}
      />
    </FieldGroup>
  );
};

export default FieldBoolean;
