import { IItemConfig } from '../../chadmin';
import { TColumns } from '../../chadmin';
import { CollectionType } from '../../collections';
import { IProductSubscription } from '../../store/data-types/product-subscription';

export const PRODUCT_SUBSCRIPTIONS: CollectionType =
  'admin/productsubscriptions';

const DETAIL_ROUTE = '${id}';

const PRODUCT_VALUE_MAP = {
  ANNOTATE: 'Annotate',
  MANAGE: 'Manage',
  MANAGE_AWARDING_BODIES: 'Manage Awarding Bodies',
};

const PRODUCT_CHOICES = [
  {
    display_name: 'Annotate',
    value: 'ANNOTATE',
  },
  {
    display_name: 'Manage',
    value: 'MANAGE',
  },
  {
    display_name: 'Manage Awarding Bodies',
    value: 'MANAGE_AWARDING_BODIES',
  },
];

// list
export const LIST_COLUMNS: TColumns<IProductSubscription> = [
  { name: 'name', display_name: 'Name', type: 'text', sortable: true },
  {
    name: 'product',
    display_name: 'Product',
    type: 'custom',
    customItemHandler: (item: IProductSubscription) =>
      PRODUCT_VALUE_MAP[item.product] || item.product,
    sortable: true,
  },
  {
    name: 'active_from',
    display_name: 'Active From',
    type: 'datetime',
    sortable: true,
  },
  {
    name: 'active_to',
    display_name: 'Active To',
    type: 'datetime',
    sortable: true,
  },
  {
    name: 'actions',
    display_name: 'Actions',
    type: 'detail',
    link_text: 'Manage',
    route: DETAIL_ROUTE,
  },
  { name: 'id', display_name: 'Delete', type: 'remove', sortable: false },
];

// detail
export const DETAIL_FIELDS = [
  'name',
  'product',
  'institution',
  'active_from',
  'active_to',
];

export const DETAIL_FIELD_OPTIONS: IItemConfig = {
  active_from: {
    label: 'Active From',
    type: 'datetime',
  },
  active_to: {
    label: 'Active To',
    type: 'datetime',
  },
  name: {
    label: 'Name',
    type: 'text',
  },
  product: {
    choices: PRODUCT_CHOICES,
    label: 'Product',
    type: 'text',
  },
};
