import {
  ADD_TO_COLLECTION,
  CollectionOptions,
  DELETE_FROM_COLLECTION,
  GET_COLLECTION,
  getCollectionByName,
} from '@dabapps/redux-api-collections/dist/collections';
import {
  hasFailed,
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { Dict } from '@dabapps/simple-records';
import { AxiosPromise } from 'axios';
import { Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { reset as resetReduxForm } from 'redux-form';
import { Column, Row } from 'roe';

import {
  clearOptions,
  setOrdering,
  setPage,
  setSearch,
} from '../../actions/options';
import { AdminEditCreate } from '../../chadmin';
import { AdminList } from '../../chadmin';
import { CollectionType, default as Collections } from '../../collections';
import { getFormErrors } from '../../responses';
import { IStore } from '../../store/configureStore';
import { IUser } from '../../store/data-types/user';
import { encodeFiles } from '../../utils';
import Loading from '../loading';
import AdditionalSuccessMessage from './additional-success-message';
import {
  DETAIL_FIELD_OPTIONS,
  DETAIL_FIELDS,
  LIST_COLUMNS,
  USERS,
} from './config';

interface IProps {
  users: ReadonlyArray<IUser>;
  loading: boolean;
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
  itemIsPending: boolean;
  errors: Dict<ReadonlyArray<string>> | undefined;
  count: number;
  options: CollectionOptions;
  resetForm: typeof resetReduxForm;
  clearOptions(key: string): void;
  addItem(type: CollectionType, data: any, tag: string): AxiosPromise;
  deleteItem(id: any): void;
  search(searchTerm: string): void;
  sort(sortKey: string): void;
  changePage(pageNumber: number): void;
  getUsers(options?: CollectionOptions): void;
}

const FORM_NAME = 'user-list';

export class UsersList extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.createItem = this.createItem.bind(this);
  }

  public componentDidMount() {
    // get collection with no options set
    this.props.clearOptions(USERS);
    this.props.getUsers();
  }

  public componentWillReceiveProps(nextProps: IProps) {
    const { options, getUsers } = this.props;

    // if we've updated options, refetch the collection
    if (options !== nextProps.options) {
      getUsers(nextProps.options);
    }
  }

  public render() {
    const {
      loading,
      users,
      count,
      deleteItem,
      search,
      sort,
      changePage,
      itemHasFailed,
      itemHasSucceeded,
      itemIsPending,
      errors,
      options: { ordering, reverseOrdering, page },
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    return (
      <Row>
        <Column>
          <a href="#create" className="float-right button margin-top-large">
            Create User
          </a>
          <h3>Users</h3>
          <AdminList
            items={users}
            columns={LIST_COLUMNS}
            deleteItem={deleteItem}
            searchItems={search}
            sortItems={sort}
            sortBy={ordering}
            sortByReversed={reverseOrdering}
            changePage={changePage}
            listName={USERS}
            page={page || 1}
            itemCount={count}
          />
          <hr />
          <h3 id="create" className="anchor-offset">
            Create User
          </h3>
          <AdminEditCreate
            createItem={this.createItem}
            fields={DETAIL_FIELDS}
            itemOptions={DETAIL_FIELD_OPTIONS}
            formName={FORM_NAME}
            setPendingUploadInForm={console.log}
            itemErrors={errors}
            loading={loading}
            itemHasFailed={itemHasFailed}
            itemHasSucceeded={itemHasSucceeded}
            itemIsPending={itemIsPending}
            additionalSuccessMessage={<AdditionalSuccessMessage />}
          />
        </Column>
      </Row>
    );
  }

  private createItem(data: any) {
    encodeFiles(data).then(encoded => {
      this.props
        .addItem(USERS, encoded, USERS)
        .then(() => this.props.resetForm(FORM_NAME));
    });
  }
}

const defaultOptions: CollectionOptions = {};

function mapStateToProps({ collections, responses, options }: IStore) {
  const { results: users, count } = getCollectionByName(
    collections,
    'admin/users',
    USERS
  );

  const loading =
    isPending(responses, GET_COLLECTION, USERS) ||
    isPending(responses, DELETE_FROM_COLLECTION, USERS);

  return {
    loading,
    count,
    users,
    errors: getFormErrors(responses, ADD_TO_COLLECTION, USERS),
    itemHasFailed: hasFailed(responses, ADD_TO_COLLECTION, USERS),
    itemHasSucceeded: hasSucceeded(responses, ADD_TO_COLLECTION, USERS),
    itemIsPending: isPending(responses, ADD_TO_COLLECTION, USERS),
    options: options.get(USERS, defaultOptions),
  };
}

export default connect(mapStateToProps, {
  clearOptions,
  changePage: pageNumber => setPage(USERS, pageNumber),
  addItem: Collections.actions.addItem,
  deleteItem: id => Collections.actions.deleteItem(USERS, id, USERS),
  getUsers: (options = {}) =>
    Collections.actions.getCollection(
      USERS,
      {
        ...options,
        filters: Map({
          is_admin: false,
          is_osms_admin: false,
        }),
      },
      USERS
    ),
  search: searchTerm => setSearch(USERS, searchTerm),
  sort: sortKey => setOrdering(USERS, sortKey),
  resetForm: resetReduxForm,
})(UsersList);
