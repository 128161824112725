import * as React from 'react';

import * as classNames from 'classnames';

import { ISortProps } from '../types';

export interface IProps extends ISortProps {
  sortName: string;
}

export default class SortIcon extends React.PureComponent<IProps, undefined> {
  public render() {
    const { sortName, sortBy, reversed, setSortBy } = this.props;

    return (
      <span
        className={classNames('sort-icon', {
          sorted: sortName === sortBy,
          reversed,
        })}
        onClick={() => setSortBy && setSortBy(sortName)}
      />
    );
  }
}
