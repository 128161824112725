import { getCollectionResultsByName } from '@dabapps/redux-api-collections/dist/collections';
import { createSelector } from 'reselect';
import {
  INSTITUTIONS,
  TERMINOLOGY_OPTIONS_ROUTE,
  TERMINOLOGY_TYPE_VALUE_MAP,
} from '../components/institutions/config';
import { IStore } from '../store/configureStore';
// tslint:disable-next-line:no-unused-variable
import { IUser } from '../store/data-types/user';

const getUsers = ({ collections }: IStore) =>
  getCollectionResultsByName(collections, 'admin/users', INSTITUTIONS) || [];

const getTeminologies = ({ collections }: IStore) =>
  getCollectionResultsByName(
    collections,
    TERMINOLOGY_OPTIONS_ROUTE,
    INSTITUTIONS
  ) || [];

export const getUsersChoices = createSelector(
  getUsers,
  (users: ReadonlyArray<IUser>) =>
    users.map(user => ({
      value: user.id,
      display_name: `${user.name ? user.name + ' ' : ''}(${user.email ||
        user.username})`,
    }))
);

export const getTerminologyChoices = createSelector(
  getTeminologies,
  (terminologies: ReadonlyArray<IUser>) =>
    terminologies.map(terminology => ({
      value: terminology.id,
      display_name: TERMINOLOGY_TYPE_VALUE_MAP[terminology.name],
    }))
);
