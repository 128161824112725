import * as React from 'react';

import Loading from '../common/Loading';
import { TColumns, TResponse } from '../types';
import { isPending } from '../utils';
import AdminListItems from './AdminListItems';

export const PAGE_SIZE = 12;
export const DEFAULT_PAGE = 1;
export const LIST_NAME_PREFIX = 'ADMINLIST_';

export interface IProps<T> {
  items: ReadonlyArray<T>;
  changePage?: (pageNumber: number) => void;
  searchItems?: (searchString?: string) => void;
  sortItems: (sortPath: string, shouldReverse: boolean) => void;
  itemCount: number;
  pageSize?: number;
  page?: number;

  deleteItem: (itemId: string | number) => void;
  columns: TColumns<T>;
  listName: string;
  response?: TResponse;
  removeItemResponse?: TResponse;
  searchString?: string;
  sortBy?: string;
  sortByReversed?: boolean;
}

export default class AdminList<T> extends React.PureComponent<IProps<T>, void> {
  public constructor(props: IProps<T>) {
    super(props);
    this.setSortBy = this.setSortBy.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  public render() {
    const {
      columns,
      changePage,
      listName,
      response,
      removeItemResponse,
      sortBy,
      searchItems,
      sortByReversed,
      items,
      pageSize,
      page,
      itemCount,
    } = this.props;

    if (isPending(removeItemResponse)) {
      return <Loading />;
    }

    return (
      <div>
        <AdminListItems
          items={items}
          page={page || DEFAULT_PAGE}
          itemCount={itemCount}
          columns={columns}
          isLoading={isPending(response)}
          listName={listName}
          sortBy={sortBy}
          sortByReversed={sortByReversed}
          pageSize={pageSize || PAGE_SIZE}
          removeItem={this.removeItem}
          setSortBy={this.setSortBy}
          search={searchItems}
          changePage={changePage}
        />
      </div>
    );
  }

  private removeItem(itemId: string | number) {
    const { deleteItem } = this.props;
    if (window.confirm('Are you sure you want to remove?')) {
      deleteItem(itemId);
    }
  }

  private setSortBy(sortPath: string) {
    const { sortByReversed, sortBy } = this.props;
    const shouldReverse = sortPath === sortBy && !sortByReversed;
    this.props.sortItems(sortPath, shouldReverse);
  }
}
