import { AxiosError, AxiosResponse } from 'axios';

import { IStore } from '../store/configureStore';

export type UrlMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS' | 'PATCH';

export interface IAction<PayloadT, MetaT> {
  type: string;
  payload?: PayloadT;
  error?: boolean;
  meta?: MetaT;
}

export type TActionAny = IAction<any, any>;
export type Thunk<T> = (
  dispatch: DispatchCallback,
  getState: GetStateCallback
) => Promise<T>;
export type AsyncAction =
  | Thunk<AxiosResponse | null>
  | Promise<AxiosResponse | null>;
export type DispatchCallback = (action: TActionAny | AsyncAction) => void;
export type GetStateCallback = () => IStore;

export const SET_PENDING_UPLOAD_IN_FORM = 'SET_PENDING_UPLOAD_IN_FORM';

export function setPendingUploadInForm(
  form: string,
  field: string,
  file?: any
): IAction<any, void> {
  return {
    payload: { form, field, file },
    type: SET_PENDING_UPLOAD_IN_FORM,
  };
}

export const SET_UI_STATE = 'SET_UI_STATE';
export function setUIState(key: string, value: string) {
  return {
    payload: {
      key,
      value,
    },
    type: SET_UI_STATE,
  };
}

export const CLEAR_UI_STATE = 'CLEAR_UI_STATE';
export function clearUIState(key: string) {
  return {
    payload: {
      key,
    },
    type: CLEAR_UI_STATE,
  };
}

// TODO: Middleware me
export function checkForLogout(error: AxiosError) {
  const { response } = error;
  if (response) {
    if (response.status === 401 || response.status === 403) {
      // For now, log us out. In future this should probably show a dialog or something.
      window.location.replace('/accounts/logout/');
    }
  }
}
