import { Dict } from '@dabapps/simple-records';
import { List, Map } from 'immutable';
import * as moment from 'moment';
import * as _ from 'underscore';

export type ImmutableJson =
  | null
  | string
  | number
  | List<null | string | number>
  | Map<string, null | string | number>;

export type FormErrors = Dict<ReadonlyArray<string>>;

export function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (result: any) =>
      resolve((result.srcElement || result.target).result);
    reader.onerror = reject;
  });
}

export function transformFileFieldsToBase64(
  fieldData: any
): Promise<string | number> {
  if (fieldData && fieldData.constructor === File) {
    // it's a file object, turn it into a base64 string
    return getBase64(fieldData);
  }

  return Promise.resolve(fieldData);
}

export function encodeFiles(
  data: any /* not really any it's an object */
): Promise<{}> {
  // Map over the request data, and encode any files in base64.
  // FileReader is asynchronous so we have to promise.all the results.

  const fieldsToBeResolved = Object.keys(data).map(fieldName =>
    transformFileFieldsToBase64(data[fieldName])
  );

  return Promise.all(fieldsToBeResolved).then((resolvedFields: any[]) =>
    _.object(Object.keys(data), resolvedFields)
  ); // stich results back into an object
}

export function truncateText(text: string, length: number) {
  return text.length > length ? `${text.substring(0, length)}...` : text;
}

export function formatDate(date: moment.Moment): string {
  return date.format('ddd Do MMM YYYY');
}
