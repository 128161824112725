import * as React from 'react';

import { Column, Row } from 'roe';

import { IColumn, TColumns, TRows } from '../types';

import Loading from '../common/Loading';
import Table from '../common/Table';

import AdminListHeader from './AdminListHeader';
import AdminListItem from './AdminListItem';
import AdminListPagination from './AdminListPagination';
import AdminListSearch from './AdminListSearch';

export interface IAdminListItemsProps<T> {
  items: TRows<T>;
  columns: TColumns<T>;
  isLoading?: boolean;
  listName: string;
  changePage?: (pageNumber: number) => void;
  noItemsMessage?: string;
  pageSize: number;
  removeItem: (itemId: string | number) => void;
  search?: (searchString?: string) => void;
  setSortBy: (sortPath: string) => void;
  sortBy?: string;
  sortByReversed?: boolean;
  title?: string;
  searchString?: string;
  page: number;
  itemCount: number;
}

export default class AdminListItems<T> extends React.PureComponent<
  IAdminListItemsProps<T>,
  undefined
> {
  public constructor(props: IAdminListItemsProps<T>) {
    super(props);
    this.search = this.search.bind(this);
  }

  public render() {
    const {
      title,
      items,
      noItemsMessage,
      columns,
      listName,
      sortBy,
      setSortBy,
      sortByReversed,
      pageSize,
      isLoading,
      removeItem,
      search,
      changePage,
      searchString,
      itemCount,
      page,
    } = this.props;

    const showSearch = Boolean(search);

    return (
      <div className="admin-list-container">
        <Row>
          <Column xs={12}>
            {title &&
              <div className="col-1-2">
                <h3>
                  {title}
                </h3>
              </div>}
            <div className={title ? 'col-1-2' : 'col-1'}>
              {showSearch &&
                <AdminListSearch
                  onSubmit={this.search}
                  form={listName + '_search'}
                  loading={isLoading}
                  initialValues={{ searchString }}
                />}
            </div>
          </Column>
        </Row>

        {isLoading
          ? <Loading />
          : <Table
              renderRow={(item, idx: number, fields) =>
                <AdminListItem
                  key={idx}
                  item={item}
                  fields={fields}
                  removeItem={removeItem}
                />}
              renderHeaders={headers =>
                headers.map((header, idx) =>
                  <AdminListHeader
                    key={idx}
                    header={header}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    reversed={sortByReversed}
                  />
                )}
              rows={items}
              columns={columns}
              emptyMessage={noItemsMessage || 'None Found.'}
            />}

        {changePage &&
          <AdminListPagination
            items={items}
            pageSize={pageSize}
            changePage={changePage}
            page={page}
            itemCount={itemCount}
          />}
      </div>
    );
  }

  private search(data: { search?: string }) {
    const { search } = this.props;
    if (search) {
      search(data.search);
    }
  }
}
