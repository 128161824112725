import { AxiosPromise } from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';

import {
  ADD_TO_COLLECTION,
  CollectionOptions,
  GET_COLLECTION,
} from '@dabapps/redux-api-collections/dist/collections';
import {
  hasFailed,
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { Dict } from '@dabapps/simple-records';
import { AdminEditCreate } from '../../chadmin';
import { IItemConfig } from '../../chadmin';
import { CollectionType, default as Collections } from '../../collections';
import { getFormErrors } from '../../responses';
import {
  getQualificationsChoices,
  SUBSCRIPTIONS,
} from '../../selectors/subscriptions';
import { IStore } from '../../store/configureStore';
import {
  DETAIL_FIELD_OPTIONS,
  DETAIL_FIELDS,
  QUALIFICATION_SUBSCRIPTIONS,
} from './config';

interface IExternalProps {
  institution: string;
  onAdd?(): void;
}

interface IPropsWithoutActions extends IExternalProps {
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
  itemIsPending: boolean;
  errors: Dict<ReadonlyArray<string>> | undefined;
  detailFieldOptions: IItemConfig;
  loading: boolean;
}

interface IProps extends IPropsWithoutActions {
  addItem(type: CollectionType, data: any, tag?: string): AxiosPromise;
  getAllCollection(
    type: CollectionType,
    options: CollectionOptions,
    tag: string
  ): void;
}

const FORM_NAME = 'product-subscriptions-quick-add';
const QUICK_ADD_DETAIL_FIELDS = DETAIL_FIELDS.filter(
  field => field !== 'institution'
);

export class ProductSubscriptionsQuickAdd extends React.PureComponent<
  IProps,
  void
> {
  public constructor(props: IProps) {
    super(props);
    this.createItem = this.createItem.bind(this);
  }

  public componentDidMount() {
    this.props.getAllCollection('admin/courses', {}, SUBSCRIPTIONS);
  }

  public render() {
    const {
      detailFieldOptions,
      errors,
      itemHasFailed,
      itemHasSucceeded,
      itemIsPending,
      loading,
    } = this.props;
    return (
      <AdminEditCreate
        createItem={this.createItem}
        fields={QUICK_ADD_DETAIL_FIELDS}
        itemOptions={detailFieldOptions}
        formName={FORM_NAME}
        setPendingUploadInForm={console.log}
        itemErrors={errors}
        itemHasFailed={itemHasFailed}
        itemHasSucceeded={itemHasSucceeded}
        itemIsPending={itemIsPending}
        loading={loading}
      />
    );
  }

  private createItem(data: Dict<string>) {
    const dataToSend = {
      ...data,
      institution: this.props.institution,
    };
    this.props
      .addItem(
        QUALIFICATION_SUBSCRIPTIONS,
        dataToSend,
        QUALIFICATION_SUBSCRIPTIONS
      )
      .then(this.props.onAdd);
  }
}

function mapStateToProps(
  state: IStore,
  props: IExternalProps
): IPropsWithoutActions {
  const { responses } = state;

  const qualificationChoices = getQualificationsChoices(state);
  const detailFieldOptions: IItemConfig = {
    ...DETAIL_FIELD_OPTIONS,
    qualification: {
      label: 'Qualification',
      type: 'text',
      choices: qualificationChoices,
    },
  };

  const loading =
    isPending(responses, GET_COLLECTION, 'admin/courses') ||
    isPending(responses, ADD_TO_COLLECTION, QUALIFICATION_SUBSCRIPTIONS);

  return {
    ...props,
    detailFieldOptions,
    errors: getFormErrors(
      responses,
      ADD_TO_COLLECTION,
      QUALIFICATION_SUBSCRIPTIONS
    ),
    loading,
    itemHasFailed: hasFailed(
      responses,
      ADD_TO_COLLECTION,
      QUALIFICATION_SUBSCRIPTIONS
    ),
    itemHasSucceeded: hasSucceeded(
      responses,
      ADD_TO_COLLECTION,
      QUALIFICATION_SUBSCRIPTIONS
    ),
    itemIsPending: isPending(
      responses,
      ADD_TO_COLLECTION,
      QUALIFICATION_SUBSCRIPTIONS
    ),
  };
}

export default connect(mapStateToProps, {
  addItem: Collections.actions.addItem,
  getAllCollection: Collections.actions.getAllCollection,
})(ProductSubscriptionsQuickAdd);
