import * as React from 'react';

import * as classNames from 'classnames';

import { isPending } from '../utils';

import { IColumn, TColumns, TResponse } from '../types';

import Loading from '../common/Loading';
import Table from '../common/Table';
import AdminDetailItem from './AdminDetailItem';

export const DETAIL_NAME_PREFIX = 'ADMINDETAIL_';

export interface IProps<T> {
  className?: string;
  fields: TColumns<T>;
  item: T;
  response?: TResponse;
  title: string;
}

export default class AdminDetail<T> extends React.PureComponent<
  IProps<T>,
  void
> {
  public render() {
    const { item, fields, response, title, className, children } = this.props;

    return isPending(response)
      ? <Loading />
      : <div className={classNames('admin-list-detail', className)}>
          <h4>
            {title}
          </h4>
          <Table
            className="padded"
            renderRow={(field: IColumn<T>, idx) =>
              <AdminDetailItem key={idx} item={item} field={field} />}
            rows={fields}
          />
          {children}
        </div>;
  }
}
