import * as React from 'react';

import { TableHeader } from 'roe';
import { IColumn, ISortProps } from '../types';
import { parsePropertyString } from '../utils';
import SortIcon from './SortIcon';

export interface IAdminListHeaderProps<T> extends ISortProps {
  header: IColumn<T>;
}

export default class AdminListHeader<T> extends React.PureComponent<
  IAdminListHeaderProps<T>,
  void
> {
  public render() {
    const { header, setSortBy, sortBy, reversed } = this.props;
    const { display_name, sortable, name } = header;

    return (
      <TableHeader>
        {display_name}
        {sortable &&
          <SortIcon
            sortName={parsePropertyString(name).join('__')}
            sortBy={sortBy}
            setSortBy={setSortBy}
            reversed={reversed}
          />}
      </TableHeader>
    );
  }
}
