import * as React from 'react';

import FieldGroup from '../FieldGroup';
import {
  // tslint:disable-next-line:no-unused-variable
  IWrappedFieldProps,
  TWrappedFieldProps,
} from './types';

export const PASSWORD_FIELDS = ['password', 'password_1', 'password_2'];

export const FieldChar = ({
  readOnly,
  config,
  name,
  errors,
  value,
  onChange,
  className,
}: React.HTMLProps<JSX.Element> & TWrappedFieldProps) => {
  return readOnly
    ? <FieldGroup config={config} errors={errors}>
        <span className="input">
          {value}
        </span>
      </FieldGroup>
    : <FieldGroup config={config} errors={errors}>
        {config.max_length
          ? <input
              type={PASSWORD_FIELDS.indexOf(name) !== -1 ? 'password' : 'text'}
              name={name}
              placeholder={config.placeholder}
              onChange={event => onChange(event)}
              value={value}
            />
          : <input
              className="valign-top"
              type="text"
              name={name}
              placeholder={config.placeholder}
              onChange={event => onChange(event)}
              value={value}
            />}
      </FieldGroup>;
};

export default FieldChar;
