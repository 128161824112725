import 'core-js/shim';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { browserHistory, IndexRoute, Route, Router } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { Container, ModalRenderer } from 'roe';

import { List } from 'immutable';

import configureStore, { IStore } from './store/configureStore';

import AdminPage from './components/admin-page';
import AdminHome from './components/home';
import InstitutionsDetail from './components/institutions/institutions-detail';
import InstitutionsList from './components/institutions/institutions-list';
import NavBar from './components/nav/navbar';
import ProductSubscriptionsDetail from './components/product-subscriptions/product-subscriptions-detail';
import ProductSubscriptionsList from './components/product-subscriptions/product-subscriptions-list';
// tslint:disable-next-line:max-line-length
import QualificationSubscriptionsDetail from './components/qualification-subscriptions/qualification-subscriptions-detail';
import QualificationSubscriptionsList from './components/qualification-subscriptions/qualification-subscriptions-list';
import AdminUsersList from './components/users/admin-users-list';
import UsersDetail from './components/users/users-detail';
import UsersList from './components/users/users-list';

const store = configureStore();
export const historyObject = syncHistoryWithStore(browserHistory, store);

interface IProps {
  pathname: string;
  modals: List<React.ReactNode>;
}

class App extends React.PureComponent<IProps, void> {
  public render() {
    const { pathname, modals } = this.props;

    return (
      <div>
        <NavBar pathname={pathname} />
        <Container className="has-navbar">
          <AdminPage>
            {this.props.children}
          </AdminPage>
        </Container>
        <ModalRenderer modals={modals.toArray()} />
      </div>
    );
  }
}

function mapStateToProps({
  modals,
  routing: { locationBeforeTransitions: { pathname } },
}: IStore) {
  return {
    modals,
    pathname,
  };
}

const ConnectedApp = connect(mapStateToProps)(App);

const app = document.getElementById('app');

if (app) {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={historyObject}>
        <Route path="/" component={ConnectedApp}>
          <IndexRoute component={AdminHome} />
          <Route path="institutions">
            <IndexRoute component={InstitutionsList} />
            <Route path=":id" component={InstitutionsDetail} />
          </Route>
          <Route path="productsubscriptions">
            <IndexRoute component={ProductSubscriptionsList} />
            <Route path=":id" component={ProductSubscriptionsDetail} />
          </Route>
          <Route path="qualificationsubscriptions">
            <IndexRoute component={QualificationSubscriptionsList} />
            <Route path=":id" component={QualificationSubscriptionsDetail} />
          </Route>
          <Route path="users">
            <IndexRoute component={UsersList} />
            <Route path=":id" component={UsersDetail} />
          </Route>
          <Route path="adminusers">
            <IndexRoute component={AdminUsersList} />
            <Route path=":id" component={UsersDetail} />
          </Route>
        </Route>
      </Router>
    </Provider>,
    app
  );
}
