import * as React from 'react';

import { FieldErrors } from '../../types';

export interface IProps {
  errors?: FieldErrors;
}

function getErrors(fieldErrors: FieldErrors): ReadonlyArray<string> {
  if (typeof fieldErrors === 'string') {
    return [fieldErrors];
  }
  return fieldErrors;
}

export default class AdminFieldError extends React.PureComponent<IProps, void> {
  public render() {
    const { errors } = this.props;

    if (!errors) {
      return null;
    }

    const fieldErrors = getErrors(errors);

    return (
      <div className="errors">
        {fieldErrors.map(error =>
          <p className="error" key={error}>
            {error}
          </p>
        )}
      </div>
    );
  }
}
