import * as React from 'react';

import FileUpload from '../../../common/FileUpload';
import FieldGroup from '../FieldGroup';
import {
  // tslint:disable-next-line:no-unused-variable
  IWrappedFieldProps,
  TWrappedFieldProps,
} from './types';

export const FieldFile = ({
  readOnly,
  config,
  errors,
  value,
  className,
  formName,
  name,
  setUpload,
}: React.HTMLProps<JSX.Element> & TWrappedFieldProps) => {
  return readOnly
    ? <FieldGroup config={config} errors={errors}>
        <span className="input">
          <a href={value}>Download</a>
        </span>
      </FieldGroup>
    : <FieldGroup config={config} errors={errors}>
        <FileUpload
          className="input image-field"
          src={value && (value.preview || value)}
          display={(file?: File | string) =>
            file &&
            <a
              href={typeof file === 'string' ? file : (file as any).preview}
              target="_blank"
            >
              {typeof file === 'string' ? config.fileName || 'Download' : file}
            </a>}
          setPendingUpload={setUpload}
          onSubmit={() => null}
        />
      </FieldGroup>;
};

export default FieldFile;
