import { Collections } from '@dabapps/redux-api-collections';
import { ExamSpecRecord, IExamSpec } from './store/data-types/exam-spec';
import {
  IInstitution,
  IInstitutionList,
  InstitutionListRecord,
  InstitutionRecord,
  ITerminologyOption,
  TerminologyOption,
} from './store/data-types/institution';
import {
  IProductSubscription,
  ProductSubscriptionRecord,
} from './store/data-types/product-subscription';
import {
  IQualificationSubscription,
  QualificationSubscriptionRecord,
} from './store/data-types/qualification-subscription';
import { IUser, UserRecord } from './store/data-types/user';

export interface ICollections {
  'admin/institutions': IInstitutionList;
  'admin/productsubscriptions': IProductSubscription;
  'admin/qualificationsubscriptions': IQualificationSubscription;
  'admin/users': IUser;
  'admin/courses': IExamSpec;
  'admin/terminology-options': ITerminologyOption;
}

export interface IItems {
  'admin/institutions': IInstitution;
  'admin/productsubscriptions': IProductSubscription;
  'admin/qualificationsubscriptions': IQualificationSubscription;
  'admin/users': IUser;
  'admin/courses': IExamSpec;
}

export const collectionToRecordMapping = {
  'admin/institutions': InstitutionListRecord,
  'admin/productsubscriptions': ProductSubscriptionRecord,
  'admin/qualificationsubscriptions': QualificationSubscriptionRecord,
  'admin/users': UserRecord,
  'admin/courses': ExamSpecRecord,
  'admin/terminology-options': TerminologyOption,
};

export const itemToRecordMapping = {
  'admin/institutions': InstitutionRecord,
  'admin/productsubscriptions': ProductSubscriptionRecord,
  'admin/qualificationsubscriptions': QualificationSubscriptionRecord,
  'admin/users': UserRecord,
  'admin/courses': ExamSpecRecord,
};

export type CollectionType = keyof ICollections;

export default Collections<ICollections, IItems>(
  collectionToRecordMapping,
  itemToRecordMapping
);
