import * as React from 'react';
import { Column, FormGroup } from 'roe';
import { FieldErrors } from '../../types';
import AdminFieldError from './AdminFieldError';
import { IConfig } from './field-types/types';

export interface IFieldGroup {
  config: IConfig;
  errors?: FieldErrors;
}

export const FieldGroup = ({
  children,
  config,
  errors,
}: React.HTMLProps<JSX.Element> & IFieldGroup) => {
  return (
    <Column md={config.fullWidth ? 12 : 6} sm={12}>
      <FormGroup block>
        <label>
          {config.label}
        </label>
        {children}
        <AdminFieldError errors={errors} />
      </FormGroup>
    </Column>
  );
};

export default FieldGroup;
